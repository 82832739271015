<template>
    <div class="row">
        <div class="col-6 col-sm-3">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_RTN_REFERENCE'] }}
            </strong>

            <p class="mb-0">
                {{ returnModel.ReturnReference }}
            </p>
        </div>

        <div class="col-6 col-sm-3">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_MERCHANT_SETUP_ID'] }}
            </strong>

            <p class="mb-0">
                {{ returnModel.MerchantSetupLabel }}
            </p>
        </div>

        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_RTN_CREATION'] }}
            </strong>

            <p class="mb-0">
                {{ returnModel.CreatedAt.substring(0, 10) }}
            </p>
        </div>

        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_STATUS'] }}
            </strong>

            <p class="mb-0 text-success">
                {{ returnModel.Status }}
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'returnModel',
            'currencySymbol',
            'serviceRequestType',
            'serviceRequestLabel',
            'settlementProposal'
        ],
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {
            orders() {
                return this.returnModel?.orders;
            },
            showEstimatedRefundValue() {
                /* Only show the estimated refund value if the settlement type is either "refund", "store credit" or "exchange". */
                return ['refund', 'store credit', 'exchange'].includes(this.serviceRequestType);
            }
        }
    }
</script>

<style scoped>
    table thead td {
        font-weight: 700;
    }
</style>
