<template>
    <header class="header">
        <nav class="navbar navbar-expand-lg justify-space-between" :class="showMenuItems ? '' : 'container sm px-2'">
            <router-link to="/merchantSetupSelection" class="navbar-brand">
                <img v-bind:src="$store.state.header.logo" id="logo" alt="">
            </router-link>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <template v-if="showMenuItems">
                        <template v-for="item in menuitems" :key="item.name">
                            <li class="nav-item active" v-if="item.name == activeMenuItem">
                                <router-link :to="{ path: item.url }" class="nav-link">
                                    {{ $store.state.translations.translations[item.translationKey] }}
                                </router-link>
                            </li>

                            <li class="nav-item" v-else>
                                <router-link :to="{ path: item.url }" class="nav-link">
                                    {{ $store.state.translations.translations[item.translationKey] }}
                                </router-link>
                            </li>
                        </template>
                    </template>
                </ul>
            </div>

            <div class="navbar-nav-right">
                <LanguageSelector/>

                <template v-if="$store.state.portal && $store.state.portal.parameters.loginType !== 'none'">
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" class="navbar-nav-right-link" no-caret>
                        <template #button-content>
                            <a href="">
                                <img :src="require('@/assets/images/icons/user.svg')">
                            </a>
                        </template>

                        <b-dropdown-item><router-link to="/history" class="hoverColor">{{ $store.state.translations.translations['History'] }}</router-link></b-dropdown-item>
                        <b-dropdown-item><router-link to="/editProfile" class="hoverColor">{{ $store.state.translations.translations['Edit profile'] }}</router-link></b-dropdown-item>
                        <b-dropdown-item v-on:click.prevent="logout()" class="hoverColor">{{ $store.state.translations.translations['Logout'] }}</b-dropdown-item>
                    </b-dropdown>
                </template>
            </div>
        </nav>
    </header>
</template>

<script>
    import LanguageSelector from './_LanguageSelector.vue';
    import { BDropdown, BDropdownItem } from 'bootstrap-vue-3';

    export default {
        components: {
            'LanguageSelector': LanguageSelector,
            'b-dropdown': BDropdown,
            'b-dropdown-item': BDropdownItem
        },
        props: [
            'activeMenuItem'
        ],
        data() {
            return {
                menuitems: [
                    {name: 'create', translationKey: 'Create', url: '/'},
                    {name: 'ship', translationKey: 'Ship', url: '/ship'},
                    {name: 'track', translationKey: 'Track', url: '/track'}
                ],
                dropdownOpen: false,
                headerData: [],
                headerDataLoaded: false
            }
        },
        created() {
			
        },
        methods: {
            logout() {
                const url = this.$store.state.portalUrl + 'user/logout';

                this.$http.get(url).then(() => {
                    this.$store.commit('logoutClear');
                    this.$router.push({
                        path: '/login'
                    });
                });
            }
        },
        computed: {
            showMenuItems() {
                return this.$store.state.portal && (this.$store.state.portal.parameters.loginType !== 'none' || (this.$store.state.portal.parameters.loginType === 'none') && this.$store.state.portal.parameters.displayMenuItems);
            }
        }
    }
</script>

<style scoped>
    a {
        text-decoration: none !important;
        color: #212529 !important;
    }
</style>
