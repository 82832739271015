import _ from 'lodash';

const countriesStore = {
    namespaced: true,
    state: {
        countries: null,
    },
    mutations: {
        set(state, countries) {
            state.countries = _.toPairs(countries).map(c => {
                return {code: c[0], label: c[1]};
            });
        },
        clear(state) {
            state.countries = null;
        }
    },
    actions: {},
};
export default countriesStore;
