import { createWebHashHistory, createRouter } from 'vue-router';
import store from '@/store';
import WindowEventManager from '@/classes/WindowEventManager';

import MerchantSetupPage from '@/pages/MerchantSetupSelectionPage';
import OrderPage from '@/pages/OrderPage';
import ProductPage from '@/pages/ProductPage';
import Checkout from '@/pages/Checkout';
import AdditionalInfo from '@/pages/AdditionalInfo';
import AddAddress from '@/pages/AddAddress';
import SubmitForApproval from '@/pages/SubmitForApproval';
import Login from '@/pages/Login';
import Register from '@/pages/Register';
import Track from '@/pages/Track';
import Ship from '@/pages/Ship';
import ForgotPassword from '@/pages/ForgotPassword';
import RegisterOrganization from '@/pages/RegisterOrganization';
import EditProfile from '@/pages/EditProfile';
import OrderConnectorPage from '@/pages/OrderConnectorPage';
import SSO from '@/pages/SSO';
import OrderConnectorProductPage from '@/pages/OrderConnectorProductPage'
import ResetPassword from '@/pages/ResetPassword';
import ExternalLogin from '@/pages/ExternalLogin';
import CreateReturnDeepLink from '@/pages/CreateReturnDeepLink';
import UserConfirm from '@/pages/UserConfirm';
import ShipInstructions from '@/pages/ShipInstructions';
import TrackReturn from '@/pages/TrackReturn';
import Error from '@/pages/Error';
import ShipSubmit from '@/pages/ShipSubmit';
import ShipSubmitFailed from '@/pages/ShipSubmitFailed';
import Maintenance from '@/pages/Maintenance';
import PaymentStatus from '@/pages/PaymentStatus';
import History from '@/pages/History';
import SelectSettlementProposal from '@/pages/SelectSettlementProposal';

const exemptFromLoginCheck = ['/login', '/register', '/forgotPassword', '/registerOrganization', '/sso', '/resetPassword', '/externalLogin', '/externalLogin/', '/confirmUserAccount'];

const routes = [
    {
        path: '/register',
        component: Register,
        name: 'Register'
    },
    {
        path: '/registerOrganization',
        component: RegisterOrganization,
        name: 'RegisterOrganization'
    },
    {
        path: '/login',
        component: Login,
        name: 'Login'
    },
    {
        path: '/forgotPassword',
        component: ForgotPassword,
        name: 'ForgotPassword'
    },
    {
        path: '/merchantSetupSelection',
        component: MerchantSetupPage,
        name: 'MerchantSetupPage'
    },
    {
        path: '/',
        component: MerchantSetupPage,
        name: 'Index'
    },
    {
        path: '/order',
        component: OrderPage,
        name: 'OrderPage'
    },
    {
        path: '/orderConnector',
        component: OrderConnectorPage,
        name: 'OrderConnectorPage'
    },
    {
        path: '/products',
        component: ProductPage,
        name: 'ProductPage'
    },
    {
        path: '/checkout',
        component: Checkout,
        name: 'Checkout'
    },
    {
        path: '/additionalInfo',
        component: AdditionalInfo,
        name: 'AdditionalInfo'
    },
    {
        path: '/submitForApproval',
        component: SubmitForApproval,
        name: 'SubmitForApproval'
    },
    {
        path: '/addAddress',
        component: AddAddress,
        name: 'AddAddress'
    },
    {
        path: '/ship',
        component: Ship,
        name: 'Ship'
    },
    {
        path: '/track',
        component: Track,
        name: 'Track'
    },
    {
        path: '/editProfile',
        component: EditProfile,
        name: 'EditProfile'
    },
    {
        path: '/sso',
        component: SSO,
        name: 'SSO'
    },
    {
        path: '/productsFromOrderConnector',
        component: OrderConnectorProductPage,
        name: 'OrderConnectorProductPage'
    },
    {
        path: '/resetPassword',
        component: ResetPassword,
        name: 'ResetPassword'
    },
    {
        path: '/externalLogin',
        component: ExternalLogin,
        name: 'ExternalLogin'
    },
    {
        path: '/createReturnDeepLink',
        component: CreateReturnDeepLink,
        name: 'CreateReturnDeepLink'
    },
    {
        path: '/confirmUserAccount',
        component: UserConfirm,
        name: 'UserConfirm'
    },
    {
        path: '/shipInstructions',
        component: ShipInstructions,
        name: 'ShipInstructions'
    },
    {
        path: '/trackReturn',
        component: TrackReturn,
        name: 'TrackReturn'
    },
    {
        path: '/error',
        component: Error,
        name: 'Error'
    },
    {
        path: '/shipSubmit',
        component: ShipSubmit,
        name: 'ShipSubmit'
    },
    {
        path: '/shipSubmitFailed',
        component: ShipSubmitFailed,
        name: 'ShipSubmitFailed'
    },
    {
        path: '/maintenance',
        component: Maintenance,
        name: 'Maintenance'
    },
    {
        path: '/paymentStatus',
        component: PaymentStatus,
        name: 'PaymentStatus'
    },
    {
        path: '/history',
        component: History,
        name: 'History'
    },
    {
        path: '/selectSettlementProposal',
        component: SelectSettlementProposal,
        name: 'SelectSettlementProposal'
    },
];


const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async function (to, from, next) {
    /*
     * Clean events on document
     */
    WindowEventManager.instance.removeEventListener('keydown');

    const portal = await store.dispatch('getPortal');

    if (portal.parameters.maintenanceMode === true && to.fullPath !== '/maintenance') {
        return next('/maintenance');
    }

    if (portal.parameters.loginType === 'none') {
        return next();
    }

    if (exemptFromLoginCheck.indexOf(to.path) !== -1) {
        return next();
    }

    const isLoggedIn = await store.dispatch('isLoggedInRequest');
    if (!isLoggedIn) {
        return next('/login');
    }

    return next();
});

export default router;
