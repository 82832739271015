<template>
    <div class="dropdown lang-dropdown">
        <button v-b-toggle="'dropdown-menu'" class="btn btn-link dropdown-toggle" type="button"
                aria-haspopup="true" aria-expanded="false">
            <span>{{ currentLanguage.label }}</span>
            <img :src="require('@/assets/images/lang/' + currentLanguage.code + '.png')" alt="">
        </button>

        <b-collapse id="dropdown-menu" class="dropdown-menu" v-model="showLang"
                    aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" v-for="(language, index) in languages" :key="index"
               @click.prevent="selectLanguage(index)">
                <img :src="require('@/assets/images/lang/' + index + '.png')" alt="">
                {{ language }}
            </a>
        </b-collapse>
    </div>
</template>

<script>
    import {BCollapse, VBToggle} from 'bootstrap-vue-3';
    import languageSelect from '@/mixins/languageSelect.js';

    export default {
        components: {
            'b-collapse': BCollapse,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        mixins: [
            languageSelect
        ],
        data() {
            return {

            }
        },
        created() {

        },
        methods: {

        },
        computed: {
            availableLanguages() {
                return this.$store.state.header.languages;
            }
        },
        watch: {
            availableLanguages(availableLanguages) {
                if (availableLanguages && navigator.language) {
                    const browserLanguage = navigator.language.slice(0, 2);

                    if (browserLanguage !== this.$store.state.translations.currentLanguage && Object.hasOwn(availableLanguages, browserLanguage)) {
                        this.selectLanguage(browserLanguage);
                    } else if (this.$store.state.translations.currentLanguage !== 'en' && !Object.hasOwn(availableLanguages, browserLanguage)) {
                        this.selectLanguage('en');
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .lang-dropdown .dropdown-menu {
        right: auto;
        height: inherit !important;
    }

    .btn-link {
        text-decoration: none;
    }
</style>
