<template>
    <div v-if="useCases">
        <v-select class="w-100 my-2"
                  v-model="selectedUseCase"
                  :filterable="true"
                  :searchable="true"
                  :clearable="false"
                  label="RTN_MUC_LABEL"
                  :options="useCases"
                  :placeholder="$store.state.translations.translations['Please choose from the list below.']"
        >
            <template v-slot:option="option">
                <span>
                    {{ option.translatedLabel ? option.translatedLabel : option.RTN_MUC_LABEL }}
                </span>
            </template>

            <template v-slot:selected-option="option">
                <span>
                    {{ option.translatedLabel ? option.translatedLabel : option.RTN_MUC_LABEL }}
                </span>
            </template>
        </v-select>

        <template v-if="selectedUseCase && selectedUseCase.subUseCases && selectedUseCase.subUseCases.length">
            <MerchantSetupUseCaseDropdown :use-cases="selectedUseCase.subUseCases" v-model="selectedSubUseCase" class="w-100" @result="passResult" />
        </template>
    </div>
</template>

<script>
    import vSelect from 'vue-select';
    import MerchantSetupUseCaseDropdown from '@/components/_MerchantSetupUseCaseDropdown.vue';

    export default {
        name: 'MerchantSetupUseCaseDropdown',
        props: [
            'useCases',
            'value'
        ],
        components: {
            'v-select': vSelect,
            'MerchantSetupUseCaseDropdown': MerchantSetupUseCaseDropdown,
        },
        data() {
            return {
                selectedUseCase: null,
                selectedSubUseCase: null,
            }
        },
        methods: {
            passResult(result) {
                this.$emit('result', result);
            }
        },
        watch: {
            value(value) {
                if (!value) {
                    this.selectedUseCase = null;
                    this.selectedSubUseCase = null;
                }
            },
            selectedUseCase(selectedUseCase) {
                this.selectedSubUseCase = null;
                this.$emit('input', selectedUseCase);
                this.$emit('result', {
                    type: selectedUseCase.RTN_MUC_RESULT_TYPE,
                    merchantSetupId: selectedUseCase.RTN_MCP_ID,
                    url: selectedUseCase.RTN_MUC_URL,
                    id: selectedUseCase.RTN_MUC_ID,
                    message: selectedUseCase.RTN_MUC_MESSAGE,
                    translatedMessage: selectedUseCase.translatedMessage
                });
            }
        }
    }
</script>

<style scoped>

</style>
