import axios from '../classes/Axios';
import _ from 'lodash';

const OCformdataStore = {
    namespaced: true,
    state: {
        fieldNames: {},
    },
    mutations: {
        set(state, args) {
            if (_.isEmpty(state.fieldNames[args.mcpId])) {
                state.fieldNames[args.mcpId] = {};
            }

            state.fieldNames[args.mcpId] = args.fieldNames;
        },
        clear(state) {
            state.fieldNames = {};
        }
    },
    actions: {
        getFieldNames(storeArgObj, mcpId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.fieldNames && storeArgObj.state.fieldNames[mcpId]) {
                    resolve(storeArgObj.state.fieldNames[mcpId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'order/getOCFormData?mcpId=' + mcpId);

                    url.searchParams.set('mcpId', mcpId);
                    axios.get(url.href).then((data) => {
                        let setObj = {
                            mcpId: mcpId,
                            fieldNames: data.data.data
                        };
                        storeArgObj.commit('set', setObj);
                        resolve(setObj.fieldNames);
                    });
                }
            });
        }
    }
};

export default OCformdataStore;
