import axios from '../classes/Axios';
import _ from 'lodash';

const shipmentStore = {
    namespaced: true,
    state: {
        activeShippingMails: null,
        sentShippingMails: null,
        trackingUrl: null, // External tracking url
        trackingPage: null, // The tracking page within the portal
        labelUrls: null,
    },
    mutations: {
        setActiveShippingMails(state, [activeShippingMails, returnId]) {
            if (_.isEmpty(state.activeShippingMails)) {
                state.activeShippingMails = {};
            }

            state.activeShippingMails[returnId] = activeShippingMails;
        },
        setSentShippingMails(state, [sentShippingMails, returnId]) {
            if (_.isEmpty(state.sentShippingMails)) {
                state.sentShippingMails = {};
            }

            state.sentShippingMails[returnId] = sentShippingMails;
        },
        setTrackingUrl(state, [trackingUrl, returnId]) {
            if (_.isEmpty(state.trackingUrl)) {
                state.trackingUrl = {};
            }

            state.trackingUrl[returnId] = trackingUrl;
        },
        setTrackingPage(state, [trackingPage, returnId]) {
            if (_.isEmpty(state.trackingPage)) {
                state.trackingPage = {};
            }

            state.trackingPage[returnId] = trackingPage;
        },
        setLabelUrls(state, [labelUrls, returnId]) {
            if (_.isEmpty(state.labelUrls)) {
                state.labelUrls = {};
            }

            state.labelUrls[returnId] = labelUrls;
        },
        clear(state) {
            state.activeShippingMails = null;
            state.trackingUrl = null;
            state.labelUrls = null;
        }
    },
    actions: {
        getActiveShippingMails(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.activeShippingMails && storeArgObj.state.activeShippingMails[returnId]) {
                    resolve(storeArgObj.state.activeShippingMails[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'shipment/getActiveShippingMails');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let activeShippingMails = data.data.data.emailTemplates;
                        storeArgObj.commit('setActiveShippingMails', [activeShippingMails, returnId]);
                        resolve(activeShippingMails);
                    });
                }
            });
        },
        getSentShippingMails(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.sentShippingMails && storeArgObj.state.sentShippingMails[returnId]) {
                    resolve(storeArgObj.state.sentShippingMails[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'shipment/getSentShippingMails');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let sentShippingMails = data.data.data.emailTemplates;
                        storeArgObj.commit('setSentShippingMails', [sentShippingMails, returnId]);
                        resolve(sentShippingMails);
                    });
                }
            });
        },
        getTrackingUrl(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.trackingUrl && storeArgObj.state.trackingUrl[returnId]) {
                    resolve(storeArgObj.state.trackingUrl[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'shipment/getTrackingUrl');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let trackingUrl = data.data.data.trackingUrl;
                        storeArgObj.commit('setTrackingUrl', [trackingUrl, returnId]);
                        resolve(trackingUrl);
                    });
                }
            });
        },
        getTrackingPage(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.trackingPage && storeArgObj.state.trackingPage[returnId]) {
                    resolve(storeArgObj.state.trackingPage[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'shipment/getTrackingPage');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let trackingPage = data.data.data.trackingPage;
                        storeArgObj.commit('setTrackingPage', [trackingPage, returnId]);
                        resolve(trackingPage);
                    });
                }
            });
        },
        getLabelUrls(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.labelUrls && storeArgObj.state.labelUrls[returnId]) {
                    resolve(storeArgObj.state.labelUrls[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'shipment/getLabelUrls');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let labelUrls = {
                            'shippingLabelUrls': data.data.data.shippingLabelUrls,
                            'packingSlipUrl': data.data.data.packingSlipUrl,
                            'noPrintLabelUrls': data.data.data.noPrintLabelUrls,
                            'shippingInvoiceUrl': data.data.data.shippingInvoiceUrl,
                        };
                        storeArgObj.commit('setLabelUrls', [labelUrls, returnId]);
                        resolve(labelUrls);
                    });
                }
            });
        },
    }
};

export default shipmentStore;
