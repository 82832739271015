<template>
    <section class="instructions-section">
        <div class="instructions-section-title mb-0">
            <div class="container sm">
                <div class="content-block"
                     v-if="content[currentLanguage] && content[currentLanguage][section] && content[currentLanguage][section].RTN_PCT_ACTIVE != 0">
                    <h1 v-if="content[currentLanguage][section].RTN_PCT_TITLE">
                        {{ content[currentLanguage][section].RTN_PCT_TITLE }}
                    </h1>

                    <h2 v-if="content[currentLanguage][section].RTN_PCT_SUB_TITLE">
                        {{ content[currentLanguage][section].RTN_PCT_SUB_TITLE }}
                    </h2>

                    <b-collapse id="content-block--content-dropdown"
                                v-if="content[currentLanguage][section].RTN_PCT_CONTENT"
                                v-model="isShowingContent">
                        <div v-html="content[currentLanguage][section].RTN_PCT_CONTENT"></div>
                    </b-collapse>

                    <a v-b-toggle="'content-block--content-dropdown'" class="back-link pt-1 pb-0" v-if="content[currentLanguage][section].RTN_PCT_CONTENT">
                        <span v-if="isShowingContent">
                            {{ $store.state.translations.translations['Hide text'] }}
                        </span>

                        <span v-else>
                            {{ $store.state.translations.translations['Show text'] }}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {BCollapse, VBToggle} from 'bootstrap-vue-3';

    export default {
        components: {
            'b-collapse': BCollapse,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        props: [
            'section',
        ],
        data() {
            return {
                content: this.$store.state.portalContent.content,
                currentLanguage: this.$store.state.translations.currentLanguage,
                isShowingContent: true,
            };
        },
    }
</script>

<style scoped>
    .content-block h1 {
        font-size: 28px;
    }

    .content-block h2 {
        margin-bottom: 20px;
    }

    .back-link {
        margin-bottom: -25px;
        color: var(--color-primary);
    }

    .back-link:hover {
        color: var(--color-primary);
        cursor: pointer;
    }
</style>