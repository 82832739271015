<template>
    <div>
        <div class="container-fluid overflow-x-hidden">
            <div class="row">
                <div class="col-lg-6 block-left">
                    <nav class="navbar lang-absolute-container">
                        <LanguageSelector/>
                    </nav>

                    <div class="block-left-content">
                        <h1>
                            {{ $store.state.translations.translations['Account verification'] }}
                        </h1>

                        <template v-if="!isLoading">
                            <template v-if="response">
                                <template v-if="response.type === 'success'">
                                    <p class="mt-5">
                                        {{ response.message }}
                                    </p>

                                    <router-link :to="{name: 'MerchantSetupPage'}" class="btn btn-primary md mt-5">
                                        {{ $store.state.translations.translations['Continue'] }}
                                    </router-link>
                                </template>

                                <p class="mt-5" v-else>
                                    {{ response.message }}
                                </p>
                            </template>

                            <template v-else>
                                {{ $store.state.translations.translations['Something went wrong while trying to verify the user account.'] }}
                            </template>
                        </template>
                    </div>
                </div>

                <div class="col-6 block-right">

                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import LanguageSelector from '@/components/_LanguageSelector.vue';

    export default {
        components: {
            'LanguageSelector': LanguageSelector,
        },
        data() {
            return {
                response: null,
                isLoading: true,
            }
        },
        created() {
            const url = new URL(this.$store.state.portalUrl + 'user/confirm');
            url.searchParams.set('id', this.$route.query.id);
            url.searchParams.set('token', this.$route.query.token);

            this.$http.get(url.href).then((data) => {
                if (data?.data?.data?.response) {
                    this.response = data.data.data.response;
                }

                this.isLoading = false;
            });
        },
        methods: {}
    }
</script>

<style>
    .icon-icon-eye {
        cursor: pointer;
    }
</style>
