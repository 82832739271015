<template>
    <div class="page">
        <SspHeader activeMenuItem="ship"/>

        <main class="main">
            <section class="request-shipment-section">
                <div class="container sm">
                    <h1>
                        {{ $store.state.translations.translations['Request shipment'] }}
                    </h1>
                </div>
            </section>

            <div class="container sm" v-if="isLoading">
                {{ $store.state.translations.translations['Loading returns...'] }}
            </div>

            <div class="container sm" v-else-if="isEmpty($store.state.shipReturns.returns)">
                {{ $store.state.translations.translations['No returns for shipping.'] }}
            </div>

            <div class="container sm" v-else>
                <div class="custom-table">
                    <div class="custom-table-head">
                        <div class="custom-table-head-cell one">
                            {{ $store.state.translations.attributeLabels['RTN_RTN_REFERENCE'] }}
                        </div>

                        <div class="custom-table-head-cell two">
                            {{ $store.state.translations.attributeLabels['RTN_MERCHANT_SETUP_ID'] }}
                        </div>

                        <div class="custom-table-head-cell three">
                            {{ $store.state.translations.attributeLabels['RTN_RTN_CREATION'] }}
                        </div>

                        <div class="custom-table-head-cell four">
                            {{ $store.state.translations.attributeLabels['RTN_STATUS'] }}
                        </div>

                        <div class="custom-table-head-cell five">

                        </div>
                    </div>

                    <div class="custom-table-body" v-for="rtn in $store.state.shipReturns.returns" :key="rtn.ReturnId">
                        <div class="custom-table-body-row">
                            <div class="custom-table-td one">
                                {{ rtn.ReturnReference }}
                            </div>

                            <div class="custom-table-td two">
                                {{ rtn.MerchantSetupLabel }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ translations.createdAt }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ translations.status }}
                            </div>

                            <div class="custom-table-td three">
                                {{ rtn.CreatedAt }}
                            </div>

                            <div class="custom-table-td four">
                                <span class="text-success">
                                    {{ rtn.Status }}
                                </span>
                            </div>

                            <div class="custom-table-td five">
                                <router-link :to="{name: 'AddAddress', query: {returnId: rtn.ReturnId, orderId: rtn.OrderId, merchantSetupId: rtn.MerchantSetupId}}" class="btn btn-primary">
                                    {{ $store.state.translations.translations['Request shipment'] }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import _ from 'lodash';

    export default {
        name: 'ShipPage',
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
        },
        data() {
            return {
                appBaseUrl: this.$store.state.portalUrl,
                isLoading: false,
                returns: [],
                translations: {
                    status: this.$store.state.translations.attributeLabels['RTN_STATUS'],
                    createdAt: this.$store.state.translations.attributeLabels['RTN_RTN_CREATION'],
                }
            };
        },
        created() {
            this.isLoading = true;

            this.$store.dispatch('shipReturns/getAll').then(() => {
                this.isLoading = false;
            });
        },
        mounted() {

        },
        methods: {
            isEmpty(value) {
                return _.isEmpty(value);
            }
        },
    }
</script>

<style>

</style>
