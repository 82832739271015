<template>
    <div>
        <label class="mt-3" v-if="title">
            {{ title }}
        </label>

        <div class="model-number-list">
            <div class="model-item-wrapper" v-for="articleGroup in articleGroups" :key="articleGroup.ArticleGroupId">
                <a href="" class="model-item" @click.prevent="selectArticleGroup(articleGroup)">
                    <span>{{ articleGroup.Name }}</span>
                    <img :src="articleGroup.GroupImage" class="img-fluid" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'articleParentGroupId'],
        data() {
            return {
                articleGroups: null,
                orderId: this.$route.query.orderId,
            }
        },
        created() {
            let url = new URL(this.$store.state.portalUrl + 'article/getGroups');
            url.searchParams.set('merchantSetupId', this.$route.query.merchantSetupId);

            if (this.articleParentGroupId) {
                url.searchParams.set('parentGroupId', this.articleParentGroupId);
            }

            this.$http.get(url.href).then((data) => {
                this.articleGroups = data.data.data.articleGroups;
            });
        },
        methods: {
            selectArticleGroup: function (articleGroup) {
                this.$parent.$parent.selectArticleGroup(articleGroup);
            },
        }
    }
</script>

<style scoped>
    .model-item img {
        max-width: 180px;
        max-height: 150px;
        margin: 10px;
    }
</style>