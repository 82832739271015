var languageSelectMixin = {
    data() {
        return {
            showLang: false,
            languages: {},
            languagesEn: {},
            currentLanguage: {
                label: '',
                code: this.$store.state.translations.currentLanguage,
            },
        };
    },
    created() {
        this.$store.dispatch('header/get').then((data) => {
            if (data && data.languages) {
                this.languagesEn = data.languages;
                this.setLangsTranslated();
            }
        });
    },
    methods: {
        selectLanguage(language) {
            const url = new URL(this.$store.state.portalUrl + 'app/setLanguage');
            const params = new URLSearchParams();
            params.append('language', language);

            this.currentLanguage = {
                label: this.$store.state.translations.translations[this.languages[language]],
                code: language
            };

            this.showLang = false;

            this.$http.post(url.href, params).then((data) => {
                this.$store.commit('translations/setCurrentLanguage', language);
                this.$store.commit('translations/set', data.data.data.translations);
                this.$store.commit('translations/setAttributeLabels', data.data.data.attributeLabels);
                this.$store.commit('portalContent/set', [data.data.data.portalContent, language]);
                this.setLangsTranslated();

                const getCountriesUrl = new URL(this.$store.state.portalUrl + 'app/getCountries');

                this.$http.get(getCountriesUrl.href).then((data) => {
                    this.$store.commit('countries/set', data.data.data.countries);
                });

                global.context.authorization.$emit('switchedLanguage');
            });
        },
        setLangsTranslated() {
            for (const key in this.languagesEn) {
                this.languages[key] = this.$store.state.translations.translations[this.languagesEn[key]];
            }

            this.currentLanguage.label = this.languages[this.currentLanguage.code];
        }
    }
};

export default languageSelectMixin;
