<template>
    <div class="page">
        <SspHeader activeMenuItem="create"/>

        <InstructionsSection contentBlockSection="AdditionalInfoPage" progressBarCurrentStep="additionalInfo"/>

        <form action="#">
            <div class="container sm">
                <div v-if="isSaving">
                    {{ $store.state.translations.translations['Saving...'] }}
                </div>

                <div :class="isSaving ? 'd-none' : 'd-block'" v-if="additionalInfoLoaded">
                    <div class="row">
                        <div class="col-12">
                            <InputFieldError attribute="attributes" :errors="errors"/>
                        </div>

                        <div class="col-md-6 my-3" v-if="params.returnReasonDisplay === 'return'">
                            <label>{{ $store.state.translations.attributeLabels['Return reason'] }} *</label>

                            <v-select :options="returnReasons"
                                      :clearable="false"
                                      v-if="returnReasons"
                                      :filterable="true"
                                      :reduce="returnReason => returnReason.name"
                                      :searchable="true"
                                      v-model="selectedReturnReason"
                                      :placeholder="$store.state.translations.placeHolders['Return reason'] ? $store.state.translations.placeHolders['Return reason'] : $store.state.translations.translations['Please select your return reason.']"
                            >
                            </v-select>

                            <InputFieldError attribute="return reason" :errors="errors"/>
                        </div>

                        <div class="col-md-6 my-3" v-if="params.serviceRequestDisplay === 'return' && serviceRequests.length">
                            <label>{{ $store.state.translations.attributeLabels['Service request'] }} *</label>

                            <v-select :options="serviceRequests"
                                      :clearable="false"
                                      v-if="serviceRequests"
                                      :filterable="true"
                                      :reduce="serviceRequest => serviceRequest.name"
                                      :searchable="true"
                                      v-model="selectedServiceRequest"
                                      :placeholder="$store.state.translations.placeHolders['Reimbursement request'] ? $store.state.translations.placeHolders['Reimbursement request'] : $store.state.translations.translations['Please select your service request.']"
                            >
                            </v-select>

                            <InputFieldError attribute="service request" :errors="errors"/>
                        </div>
                    </div>

                    <CustomAttributesForm v-bind:customAttributes="customAttributes" :errors="errors" ref="customAttrForm"/>
                </div>
            </div>

            <div class="container sm">
                <InputFieldError attribute="general" :errors="errors"/>

                <div class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center pt-3 pt-md-4 mb-5" v-if="!isSaving">
                    <button type="button" class="btn md btn-secondary m-3" @click="previous()">
                        {{ $store.state.translations.translations['Back'] }}
                    </button>

                    <button type="button" name="button" class="btn md btn-primary m-1 m-md-3" @click="save()">
                        {{ $store.state.translations.translations['Next'] }}
                    </button>
                </div>
            </div>
        </form>

        <SspFooter></SspFooter>
    </div>
</template>


<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import CustomAttributesForm from '@/components/_CustomAttributesForm.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';
    import _ from 'lodash';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'CustomAttributesForm': CustomAttributesForm,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
        },
        data() {
            return {
                params: {},
                returnId: this.$route.query.returnId,
                merchantSetupId: this.$route.query.merchantSetupId,
                orderId: this.$route.query.orderId,
                customAttributes: null,
                returnReasons: {},
                serviceRequests: {},
                selectedReturnReason: null,
                selectedServiceRequest: null,
                additionalInfoLoaded: false,
                form: {},
                isSaving: false,
                errors: {},
            }
        },
        created() {
            this.$store.dispatch('mcpModule/getAll').then(() => {
                this.merchantSetupsLoaded = true;
                this.params = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].parameters;
            });

            const url = new URL(this.$store.state.portalUrl + 'return/getAdditionalInfo');
            url.searchParams.set('returnId', this.returnId);

            this.$http.get(url.href).then((data) => {
                this.additionalInfoLoaded = true;
                this.customAttributes = data.data.data.customAttributes;

                if (data.data.data.returnReasons) {
                    this.returnReasons = _.toPairs(data.data.data.returnReasons).map(a => {
                        return {name: a[0], label: a[1]};
                    });

                    if (this.returnReasons.length === 1) {
                        this.selectedReturnReason = this.returnReasons[0].name;
                    }
                }

                if (data.data.data.serviceRequests) {
                    this.serviceRequests = _.toPairs(data.data.data.serviceRequests).map(a => {
                        return {name: a[0], label: a[1]};
                    });

                    if (this.serviceRequests.length === 1) {
                        this.selectedServiceRequest = this.serviceRequests[0].name;
                    }
                }
            });
        },
        methods: {
            save() {
                // this.$refs.customAttrForm.getData();
                // this.next();

                this.isSaving = true;

                let data = {};

                if (this.params.returnReasonDisplay === 'return') {
                    data['returnReason'] = this.selectedReturnReason;
                }

                if (this.params.serviceRequestDisplay === 'return') {
                    data['serviceRequest'] = this.selectedServiceRequest;
                }

                const customAttributesData = this.$refs.customAttrForm.getData();

                const formData = new FormData();
                formData.append('ReturnModel[CUSTOM_ATTRIBUTE_OPTION]', 'SPP_RETURN_ADDITIONAL_INFORMATION');

                _.forOwn(data, function (value, key) {
                    formData.append('ReturnModel[' + key + ']', value !== null ? value : '');
                });

                _.forOwn(customAttributesData, function (value, key) {
                    if (value !== null) {
                        formData.append('ReturnModel[' + key + ']', value);
                    }
                });

                let url = new URL(this.$store.state.portalUrl + 'return/survey');
                url.searchParams.set('returnId', this.returnId);

                this.$http.post(url.href, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((data) => {
                    if (data.data.data.errors) {
                        this.isSaving = false;
                        this.errors = data.data.data.errors;
                    } else {
                        this.next();
                    }
                });
            },
            next() {
                this.$router.push({
                    name: 'Checkout',
                    query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
                });
            },
            previous() {
                const mcp = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId];
                const productPage = mcp.hasConnector ? 'OrderConnectorProductPage' : 'ProductPage';

                this.$router.push({
                    name: productPage,
                    query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
                });
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
