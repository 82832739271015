<template>
    <div class="page">
        <SspHeader activeMenuItem="create"/>

        <main class="main">
            <InstructionsSection contentBlockSection="AddProductsPage" progressBarCurrentStep="addProducts"/>

            <form action="#">
                <template v-if="$store.state.OC.loaded && merchantSetupsLoaded">
                    <div class="container container-full-width-md container-md-desktop">
                        <label class="pb-1 ps-2">
                            {{ $store.state.translations.translations['Your products'] }}
                        </label>
                    </div>

                    <OCProductSelect :params="params"/>
                    <ProductOverview ref="productOverview" :params="params"/>
                </template>

                <div class="container mt-2">
                    <div
                        class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center pt-3 pt-md-4 mb-5">
                        <button type="button" class="btn md btn-secondary m-3" @click="previous()">
                            {{ $store.state.translations.translations['Back'] }}
                        </button>

                        <button type="button" class="btn md btn-primary m-1 m-md-3" @click="next()" v-if="$store.state.products.products[orderId] && Object.keys($store.state.products.products[orderId]).length >  0">
                            {{ $store.state.translations.translations['Next'] }}
                        </button>
                    </div>
                </div>
            </form>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
import ProductOverview from '@/components/_ProductOverview';
import SspHeader from '@/components/_Header.vue';
import SspFooter from '@/components/_Footer.vue';
import InstructionsSection from '@/components/_InstructionsSection.vue';
import OCProductSelect from '@/components/_OCProductSelect';

export default {
    components: {
        'ProductOverview': ProductOverview,
        'SspHeader': SspHeader,
        'SspFooter': SspFooter,
        'InstructionsSection': InstructionsSection,
        'OCProductSelect': OCProductSelect,
    },
    data() {
        return {
            selectedArticle: null,
            articles: null,
            merchantSetupsLoaded: false,
            merchantSetupId: this.$route.query.merchantSetupId,
            returnId: this.$route.query.returnId,
            orderId: this.$route.query.orderId,
            productBaseUrl: this.$store.state.portalUrl + 'product/',
            params: null
        }
    },
    created() {
        //load merchant setups (only)
        this.$store.dispatch('mcpModule/getAll').then(() => {
            this.merchantSetupsLoaded = true;
            this.params = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].parameters;
        });

        this.$store.dispatch('getHasAdditionalInfoStep', this.returnId);

        this.$store.commit('OC/clear');
        this.$store.commit('OC/setLoaded', false);
        this.$store.dispatch('OC/getAll', {returnId: this.returnId, orderId: this.orderId});
    },
    methods: {
        next() {
            let nextUrl = null;

            if (this.$store.state.hasAdditionalInfoStep?.[this.returnId] === true) {
                nextUrl = 'additionalInfo';
            } else {
                nextUrl = 'checkout';
            }

            this.$router.push({
                path: nextUrl,
                query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
            });
        },
        previous() {
            this.$router.push({
                path: 'orderConnector',
                query: {returnId: this.returnId, merchantSetupId: this.merchantSetupId}
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
