import axios from '../classes/Axios';
import _ from 'lodash';

const carrierStore = {
    namespaced: true,
    state: {
        selectedCarrier: null
    },
    mutations: {
        setSelectedCarrier(state, [selectedCarrier, returnId, language]) {
            if (_.isEmpty(state.selectedCarrier)) {
                state.selectedCarrier = {};
            }

            if (_.isEmpty(state.selectedCarrier[returnId])) {
                state.selectedCarrier[returnId] = {};
            }

            if (_.isEmpty(state.selectedCarrier[returnId][language])) {
                state.selectedCarrier[returnId][language] = selectedCarrier;
            }
        },
        clear(state) {
            state.selectedCarrier = null;
        }
    },
    actions: {
        getSelectedCarrier(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.selectedCarrier && storeArgObj.state.selectedCarrier[returnId] && storeArgObj.state.selectedCarrier[returnId][this.state.translations.currentLanguage]) {
                    resolve(storeArgObj.state.selectedCarrier[returnId][this.state.translations.currentLanguage]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'shipment/getCarrier');
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let carrier = data.data.data.carrier;
                        storeArgObj.commit('setSelectedCarrier', [carrier, returnId, this.state.translations.currentLanguage]);
                        resolve(carrier);
                    });
                }
            });
        }
    }
};

export default carrierStore;
