<template>
    <div>
        <SspHeader activeMenuItem="create"/>

        <main class="main">
            <section class="middle-section">
                <div class="container text-center" v-if="submitResult === 'submit-approval'">
                    <h1>
                        {{ $store.state.translations.translations['We are reviewing your return and will inform you about next steps.'] }}
                    </h1>

                    <h2>
                        {{ $store.state.translations.translations['Your reference number is'] }} {{ returnReference }}
                    </h2>

                    <img src="@/assets/images/shipment.svg" class="d-block img-fluid my-5 mx-auto" alt="">

                    <router-link class="btn btn-primary md" :to="{name: 'MerchantSetupPage'}">
                        {{ $store.state.translations.translations['Back to homepage'] }}
                    </router-link>
                </div>

                <div class="container text-center" v-else-if="submitResult === 'submit-non-returnable'">
                    <h1>
                        {{ $store.state.translations.translations['We are reviewing your return and will inform you about next steps.'] }}
                    </h1>

                    <h2>
                        {{ $store.state.translations.translations['Your reference number is'] }} {{ returnReference }}
                    </h2>

                    <img src="@/assets/images/great.svg" class="d-block img-fluid my-5 mx-auto" alt="">

                    <router-link class="btn btn-primary md" :to="{name: 'MerchantSetupPage'}">
                        {{ $store.state.translations.translations['Back to homepage'] }}
                    </router-link>
                </div>

                <div class="container text-center" v-else>
                    <template v-if="submitResult === 'submit-rejected'">
                        <h1>
                            {{ $store.state.translations.translations['We\'re sorry! Your return has been rejected'] }}
                        </h1>

                        <img src="@/assets/images/shipment.svg" class="d-block img-fluid my-5 mx-auto" alt="">
                    </template>

                    <template v-else>
                        <h1>
                            {{ $store.state.translations.translations['Failed to submit.'] }}
                        </h1>
                    </template>

                    <router-link class="btn btn-primary md" :to="{name: 'MerchantSetupPage'}">
                        {{ $store.state.translations.translations['Back to homepage'] }}
                    </router-link>
                </div>
            </section>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
        },
        data() {
            return {
                returnReference: this.$route.query.returnReference,
                submitResult: this.$route.query.submitResult,
            }
        },
        methods: {
            previous() {

            }
        }
    }
</script>

<style>

</style>
