<template>
    <div>
        <SspHeader activeMenuItem=""/>

        <main class="main">
            <section class="instructions-section">
                <div class="instructions-section-title">
                    <div class="container sm">
                        <h1>
                            {{ $store.state.translations.translations['Your account'] }}
                        </h1>

                        <h2>
                            {{ $store.state.translations.translations['Update your account for the {accountname} returns portal.'].replace('{accountname}', $store.state.portal.attributes.RTN_PTL_NAME) }}
                        </h2>
                    </div>
                </div>

                <div class="container md mb-3">
                    <div class="row">
                        <div class="col">
                            <Alert :alert="alert"/>
                        </div>
                    </div>
                </div>

                <div class="container md" v-if="isSavingProfile">
                    <div class="row">
                        <div class="col">
                            {{ savingProfileMessage }}
                        </div>
                    </div>
                </div>

                <template v-else>
                    <div class="container sm">
                        <form action="#">
                            <div class="row">
                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="first-name">
                                            {{ $store.state.translations.attributeLabels['RTN_LNA_ATTN_FIRST_NAME'] }}
                                        </label>

                                        <input id="first-name" type="text" class="form-control" v-model="user.firstName">

                                        <InputFieldError attribute="RTN_USR_FIRSTNAME" :errors="userErrors"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="last-name">
                                            {{ $store.state.translations.attributeLabels['RTN_LNA_ATTN_FAMILY_NAME'] }}
                                        </label>

                                        <input id="last-name" type="text" class="form-control" v-model="user.lastName">

                                        <InputFieldError attribute="RTN_USR_LASTNAME" :errors="userErrors"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="email">
                                            {{ $store.state.translations.attributeLabels['RTN_RTN_EMAIL'] }}
                                        </label>

                                        <input id="email" type="email" class="form-control" v-model="user.email">

                                        <InputFieldError attribute="RTN_USR_EMAIL" :errors="userErrors"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="phone">
                                            {{ $store.state.translations.attributeLabels['RTN_LNA_PHONE'] }}
                                        </label>

                                        <input id="phone" type="text" class="form-control" v-model="user.phone">

                                        <InputFieldError attribute="RTN_USR_PHONE" :errors="userErrors"/>
                                    </div>
                                </div>


                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="email">
                                            {{ $store.state.translations.translations['Password'] }}
                                        </label>

                                        <div class="input-group">

                                            <div class="input-group-prepend">
                                                <span class="icon-icon-key"></span>
                                            </div>

                                            <input v-bind:type="passwordFieldType" v-model="user.password"
                                                   @keyup="checkPasswordMatch()" class="form-control password">

                                            <div class="input-group-append">
                                                <span class="icon-icon-eye" @click="togglePasswordShow()"></span>
                                            </div>
                                        </div>

                                        <InputFieldError attribute="RTN_USR_PASSWORD" :errors="userErrors"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="email">
                                            {{ $store.state.translations.translations['Repeat password'] }}
                                        </label>

                                        <div class="input-group">

                                            <div class="input-group-prepend">
                                                <span class="icon-icon-key"></span>
                                            </div>

                                            <input v-bind:type="passwordFieldType" v-model="user.repeatPassword"
                                                   @keyup="checkPasswordMatch()" class="form-control password">

                                            <div class="input-group-append password">
                                                <span class="icon-check" v-if="passwordFieldMatch"></span>
                                                <span class="icon-icon-eye" @click="togglePasswordShow()"></span>
                                            </div>
                                        </div>

                                        <InputFieldError attribute="repeatPassword" :errors="userErrors"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="timezone-selection">
                                            {{ $store.state.translations.translations['Time zone'] }}
                                        </label>

                                        <v-select id="timezone-selection"
                                                  :options="timeZones"
                                                  :clearable="false"
                                                  :filterable="false"
                                                  :reduce="timeZone => timeZone.code"
                                                  :searchable="false"
                                                  v-model="user.timeZone"
                                        >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <template v-if="currentUser && !currentUser.OrganizationId">
                        <div class="container sm py-4">
                            <span class="label-title">
                                {{ $store.state.translations.translations['Address'] }}
                            </span>
                        </div>

                        <div class="container sm">
                            <form action="#">
                                <div class="row">
                                    <div class="col-md-6 ph-lg-4">
                                        <div class="form-group w-100">
                                            <label for="country-selection">
                                                {{ $store.state.translations.attributeLabels['RTN_LNA_COUNTY'] }}
                                            </label>

                                            <model-list-select
                                                    id="country-selection"
                                                    :list="$store.state.countries.countries"
                                                    option-value="code"
                                                    option-text="label"
                                                    v-model="selectedCountry"
                                                    :placeholder="$store.state.translations.translations['Please select your country.']"
                                                    required
                                            >
                                            </model-list-select>

                                            <InputFieldError attribute="RTN_LNA_COUNTRY" :errors="addressErrors"/>
                                        </div>
                                    </div>

                                    <div class="col-md-6 ph-lg-4">
                                        <template v-if="Object.keys(administrativeAreas).length > 0">
                                            <label for="administrative-area">
                                                {{ administrativeAreaLabel }}
                                            </label>

                                            <model-list-select
                                                    id="administrative-area"
                                                    :list="administrativeAreas"
                                                    option-value="code"
                                                    option-text="label"
                                                    v-model="address.administrativeArea"
                                                    :placeholder="$store.state.translations.translations['Select your {administrativeArea}'].replace('{administrativeArea}', administrativeAreaLabel.toLowerCase())"
                                                    required
                                            >
                                            </model-list-select>
                                        </template>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 ph-lg-4">
                                        <div class="form-group w-100">
                                            <label for="address-line-1">
                                                {{ $store.state.translations.attributeLabels['RTN_LNA_ADDRESS_LINE_1'] }} *
                                            </label>

                                            <input id="address-line-1" type="text" class="form-control" v-model="address.addressLine1">

                                            <InputFieldError attribute="RTN_LNA_ADDRESS_LINE_1" :errors="addressErrors"/>
                                        </div>
                                    </div>

                                    <div class="col-md-6 ph-lg-4">
                                        <div class="form-group w-100">
                                            <label for="address-line-2">
                                                {{ $store.state.translations.attributeLabels['RTN_LNA_ADDRESS_LINE_2'] }}
                                            </label>

                                            <input id="address-line-2" type="text" class="form-control" v-model="address.addressLine2">

                                            <InputFieldError attribute="RTN_LNA_ADDRESS_LINE_2" :errors="addressErrors"/>
                                        </div>
                                    </div>

                                    <div class="col-md-6 ph-lg-4" v-if="usePostalCode">
                                        <div class="form-group w-100">
                                            <label for="postal-code">
                                                {{ formConfig.postalCode.label ? formConfig.postalCode.label : $store.state.translations.attributeLabels['RTN_LNA_POSTAL'] }} *
                                            </label>

                                            <input id="postal-code" type="text" class="form-control" v-model="address.postalCode">

                                            <InputFieldError attribute="RTN_LNA_POSTAL" :errors="addressErrors"/>
                                        </div>
                                    </div>

                                    <div class="col-md-6 ph-lg-4">
                                        <div class="form-group w-100">
                                            <label for="city">
                                                {{ $store.state.translations.attributeLabels['RTN_LNA_CITY'] }} *
                                            </label>

                                            <input id="city" type="text" class="form-control" v-model="address.city">

                                            <InputFieldError attribute="RTN_LNA_CITY" :errors="addressErrors"/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </template>

                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-center pt-4 mb-5">
                        <button type="button" name="button" class="btn md btn-primary" @click="save()">
                            {{ $store.state.translations.translations['Save'] }}
                        </button>
                    </div>
                </template>
            </section>
        </main>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import Alert from '@/components/_Alert.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';
    import {ModelListSelect} from 'vue-search-select';
    import _ from 'lodash';

    export default {
        components: {
            'SspHeader': SspHeader,
            'Alert': Alert,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                alert: {},
                userErrors: {},
                addressErrors: {},
                portalUrl: this.$store.state.portalUrl,
                saveUserUrl: this.$store.state.portalUrl + 'user/save',
                isSavingProfile: false,
                savingProfileMessage: 'saving profile...',
                user: {
                    id: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    password: '',
                    repeatPassword: '',
                    timeZone: '',
                },
                formConfig: {
                    administrativeArea: {label: ''},
                    postalCode: {label: ''},
                },
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    postalCode: '',
                    city: '',
                    country: '',
                    administrativeArea: '',
                },
                usePostalCode: true,
                selectedCountry: null,
                administrativeAreaLabel: this.$store.state.translations.translations['Administrative area'],
                administrativeAreas: [],
                passwordFieldMatch: false,
                passwordFieldType: 'password',
                timeZones: [],
                currentUser: null,
            };
        },
        created() {
            const getUserUrl = new URL(this.portalUrl + 'user/getCurrentUser');

            this.$http.get(getUserUrl.href).then((data) => {
                this.currentUser = data.data.data.user;

                this.user.firstName = data.data.data.user.FirstName ? data.data.data.user.FirstName : '';
                this.user.lastName = data.data.data.user.LastName ? data.data.data.user.LastName : '';
                this.user.email = data.data.data.user.Email ? data.data.data.user.Email : '';
                this.user.phone = data.data.data.user.Phone ? data.data.data.user.Phone : '';
                this.user.timeZone = data.data.data.user.TimeZone ? data.data.data.user.TimeZone : '';

                if (data.data.data.address) {
                    this.address.addressLine1 = data.data.data.address.AddressLine1 ? data.data.data.address.AddressLine1 : '';
                    this.address.addressLine2 = data.data.data.address.AddressLine2 ? data.data.data.address.AddressLine2 : '';
                    this.address.postalCode = data.data.data.address.Postal ? data.data.data.address.Postal : '';
                    this.address.city = data.data.data.address.City ? data.data.data.address.City : '';
                    this.address.country = data.data.data.address.Country ? data.data.data.address.Country : '';
                    this.selectedCountry = this.address.country;
                    this.address.administrativeArea = data.data.data.address.AdministrativeArea ? data.data.data.address.AdministrativeArea : '';
                }
            });

            const getTimeZonesUrl = new URL(this.portalUrl + 'shipment/getTimeZones');

            this.$http.get(getTimeZonesUrl.href).then((data) => {
                if (data.data.data.timeZones) {
                    this.timeZones = _.toPairs(data.data.data.timeZones).map(a => {
                        return {code: a[0], label: a[1]};
                    });
                }
            });
        },
        mounted() {

        },
        methods: {
            save() {
                this.alert = {};
                let url = new URL(this.saveUserUrl);
                let params = new URLSearchParams();

                params.append('user[email]', this.user.email);
                params.append('user[firstName]', this.user.firstName);
                params.append('user[lastName]', this.user.lastName);
                params.append('user[phone]', this.user.phone);
                params.append('user[password]', this.user.password);
                params.append('user[repeatPassword]', this.user.repeatPassword);
                params.append('user[timeZone]', this.user.timeZone);

                let addressAttributes = {
                    AddressLine1: this.address.addressLine1,
                    AddressLine2: this.address.addressLine2,
                    Postal: this.address.postalCode,
                    City: this.address.city,
                    Country: this.address.country,
                    AdministrativeArea: this.address.administrativeArea,
                };

                _.forOwn(addressAttributes, function(value, key) {
                    if (value) {
                        params.append('address[' + key + ']', value);
                    }
                });

                this.isSavingProfile = true;

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingProfile = false;
                    this.alert = data.data.data.alert;
                    this.userErrors = data.data.data.userErrors;
                    this.addressErrors = data.data.data.addressErrors;

                    if (!this.userErrors) {
                        this.$store.dispatch('getCurrentUser').then((user) => {
                            user.FirstName = this.user.firstName;
                            user.LastName = this.user.lastName;
                            user.Phone = this.user.phone;
                            user.Email = this.user.email;
                            user.TimeZone = this.user.timeZone;

                            this.$store.commit('setCurrentUser', user);
                        });
                    }
                });
            },
            togglePasswordShow() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            },
            checkPasswordMatch() {
                this.passwordFieldMatch = this.user.password !== '' && this.user.password === this.user.repeatPassword;
            },
            getAdministrativeAreas() {
                let administrativeAreasUrl = new URL(this.$store.state.portalUrl + 'return/getAdministrativeAreas');
                administrativeAreasUrl.searchParams.set('countryCode', this.address.country);

                this.$http.get(administrativeAreasUrl.href).then((data) => {
                    this.administrativeAreas = [];

                    if (data.data.data.administrativeAreas) {
                        this.administrativeAreas = _.toPairs(data.data.data.administrativeAreas).map(a => {
                            return {code: a[0], label: a[1]};
                        });
                    }

                    if (Object.keys(this.administrativeAreas).length !== 0) {
                        let administrativeAreaLabelUrl = new URL(this.$store.state.portalUrl + 'return/getAdministrativeAreaLabel');
                        administrativeAreaLabelUrl.searchParams.set('countryCode', this.address.country);

                        this.$http.get(administrativeAreaLabelUrl.href).then((data) => {
                            if (data.data.data.administrativeAreaLabel) {
                                this.administrativeAreaLabel = data.data.data.administrativeAreaLabel;
                            }
                        });
                    }
                });
            },
            getAddressFormConfig() {
                let addressFormConfigUrl = new URL(this.portalUrl + 'shipment/getAddressFormConfig');
                addressFormConfigUrl.searchParams.set('countryCode', this.address.country);

                this.$http.get(addressFormConfigUrl.href).then((data) => {
                    this.formConfig.postalCode = data.data.data.formConfig.postalCode;
                    this.usePostalCode = !!this.formConfig.postalCode.label;
                });
            }
        },
        watch: {
            selectedCountry(value) {
                if (value === null || value === '') {
                    this.selectedAdministrativeArea = null;
                    this.administrativeAreas = [];
                    return;
                }

                this.address.country = value;
                this.getAdministrativeAreas();
                this.getAddressFormConfig();
            },
        }
    }
</script>

<style scoped>
    .icon-icon-eye {
        cursor: pointer;
    }
</style>
