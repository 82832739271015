<template>
    <div>
        <div class="container sm" v-if="isLoading">
            <div class="row">
                <div class="col-md-6 pe-lg-4">
                    <div class="form-group w-100 mb-0">
                        {{ $store.state.translations.translations['Loading settlement options...'] }}
                    </div>
                </div>
            </div>
        </div>

        <div class="container sm" v-else-if="settlementProposalOptions.length === 0">
            <div class="row">
                <div class="col-12">
                    <div class="form-group w-100 mb-0">
                        {{ $store.state.translations.translations['There are no available settlement options.'] }}
                    </div>
                </div>
            </div>
        </div>

        <div class="container sm" v-else>
            <div class="shipping-methods overlay-container">
                <div class="overlay" v-if="isSaving">
                    <div class="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
                </div>

                <div class="row" :class="isSaving ? 'opacity-50' : ''">
                    <div class="shipping-method col-12 col-lg-6" v-for="settlementProposal in settlementProposalOptions" :key="settlementProposal.RTN_SMP_ID" @click="selectSettlementProposal(settlementProposal)">
                        <div class="shipping-block" ref="settlementProposalOption" v-bind:class="{ active: settlementProposal.RTN_SMP_ID == selectedSettlementProposalId }" :style="{'height': settlementProposalOptionHeight}">
                            <div class="row">
                                <div class="col-12">
                                    <h3>
                                        {{ settlementProposal.RTN_SMP_LABEL ?? settlementProposal.serviceRequestLabel }}
                                    </h3>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <ul class="carrier-details">
                                        <li v-if="settlementProposal.customerCosts" class="mt-3">
                                            <strong>
                                                {{ currencySymbol }}
                                                {{ settlementProposal.customerCosts }}
                                            </strong>

                                            | {{ $store.state.translations.translations['These costs will be deducted from your refund.'] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container sm" v-if="Object.keys(errors).length > 0">
            <div class="row mb-3">
                <div class="col">
                    <InputFieldError :errors="errors"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'InputFieldError': InputFieldError
        },
        emits: [
            'hasSavedSettlementProposal'
        ],
        data() {
            return {
                errors: {},
                isLoading: false,
                isSaving: false,
                settlementProposalOptions: [],
                selectedSettlementProposalId: null,
                settlementProposalOptionHeight: null,
                currencySymbol: null,
            }
        },
        created() {
            this.getSettlementProposalOptions();
        },
        methods: {
            getSettlementProposalOptions() {
                this.isLoading = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/getSettlementProposalOptions');
                url.searchParams.set('returnId', this.$route.query.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoading = false;
                    this.settlementProposalOptions = data?.data?.data?.settlementProposalOptions;
                    this.currencySymbol = data?.data?.data.currencySymbol;

                    /* Auto-select the settlement proposal if there is only 1 option. */
                    if (this.settlementProposalOptions?.length === 1) {
                        this.selectSettlementProposal(this.settlementProposalOptions[0]);
                    }
                });
            },
            selectSettlementProposal(settlementProposal) {
                this.errors = {};
                this.isSaving = true;
                this.selectedSettlementProposalId = settlementProposal.RTN_SMP_ID;

                const selectSettlementProposalUrl = new URL(this.$store.state.portalUrl + 'shipment/selectSettlementProposal');
                selectSettlementProposalUrl.searchParams.set('returnId', this.$route.query.returnId);

                const params = new URLSearchParams();
                params.append('SettlementProposal[RTN_SMP_ID]', settlementProposal.RTN_SMP_ID);

                this.$http.post(selectSettlementProposalUrl.href, params).then((data) => {
                    this.isSaving = false;

                    if (data?.data?.data?.errors) {
                        this.errors = data.data.data.errors;
                        this.selectedSettlementProposalId = null;
                    } else {
                        this.hasSelectedSettlementProposal = true;
                        this.$emit('hasSavedSettlementProposal', true);
                    }
                });
            },
            calculateSettlementProposalOptionHeight() {
                this.settlementProposalOptionHeight = null;

                this.$nextTick(() => {
                    if (this.$refs.settlementProposalOption) {
                        let scrollHeight = 0;

                        for (let i = 0; i < this.$refs.settlementProposalOption.length; i++) {
                            if (this.$refs.settlementProposalOption[i].offsetHeight > scrollHeight) {
                                scrollHeight = this.$refs.settlementProposalOption[i].offsetHeight;
                            }
                        }

                        this.settlementProposalOptionHeight = scrollHeight + 'px';
                    }
                });
            },
        },
        watch: {
            settlementProposalOptions() {
                this.calculateSettlementProposalOptionHeight();
            },
        }
    }
</script>

<style scoped>

</style>
