<template>
    <div class="page">
        <SspHeader activeMenuItem="ship"/>

        <main class="main">
            <InstructionsSection contentBlockSection="RequestShipmentPage" progressBarCurrentModule="ship" progressBarCurrentStep="selectCarrier"/>

            <ShowProductDetails class="pb-4"/>

            <template v-if="isSubmittingForm">
                <div class="container sm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group w-100">
                                {{ submittingFormMessage }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-else-if="currentUser && currentUser.OrganizationId && organizationLocationsInCountry && organizationLocationsInCountry.length === 0 && !isLoadingOrganizationLocations">
                <div class="container sm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group w-100">
                                {{ $store.state.translations.translations['No ship-from location has been created for the selected country. Please inform your contact person. You can ship the return when the ship-from location has been created.'] }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div v-else>
                <!-- Start Your address -->

                <div class="container sm py-4">
                    <span class="label-title">
                        {{ $store.state.translations.translations['Your address'] }}
                    </span>
                </div>

                <div class="container sm overlay-container" v-if="!isLoadingForm">
                    <div class="overlay" v-if="isSavingAddress">
                        <div class="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
                    </div>

                    <div :class="isSavingAddress ? 'opacity-50' : ''">
                        <template v-if="currentUser && currentUser.OrganizationId && organizationLocationsInCountry && organizationLocationsInCountry.length > 1">
                            <label for="organization-location">
                                {{ $store.state.translations.translations['Select the location from where the products will be returned'] }}
                            </label>

                            <model-list-select
                                    id="organization-location"
                                    class="mb-5"
                                    :list="organizationLocationsInCountry"
                                    option-value="value"
                                    option-text="text"
                                    v-model="selectedOrganizationLocation"
                                    :placeholder="$store.state.translations.translations['Select your location']"
                                    required
                            >
                            </model-list-select>
                        </template>

                        <div class="row">
                            <template v-if="showAddressForm">
                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="address-line-1">
                                            {{ $store.state.translations.attributeLabels['RTN_LNA_ADDRESS_LINE_1'] }} *
                                        </label>

                                        <input id="address-line-1" type="text" class="form-control"
                                               v-model="address.addressLine1" :disabled="(currentUser && currentUser.OrganizationId) || isSavingAddress"
                                               @change="saveAddress()">

                                        <InputFieldError attribute="RTN_LNA_ADDRESS_LINE_1" :errors="errorsAddress"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="address-line-2">
                                            {{ $store.state.translations.attributeLabels['RTN_LNA_ADDRESS_LINE_2'] }}
                                        </label>

                                        <input id="address-line-2" type="text" class="form-control"
                                               v-model="address.addressLine2" :disabled="(currentUser && currentUser.OrganizationId) || isSavingAddress"
                                               @change="saveAddress()">

                                        <InputFieldError attribute="RTN_LNA_ADDRESS_LINE_2" :errors="errorsAddress"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4" v-if="formConfig.postalCode.label">
                                    <div class="form-group w-100">
                                        <label for="postal-code">{{ $store.state.translations.translations[formConfig.postalCode.label] }} *</label>
                                        <input id="postal-code" type="text" class="form-control" v-model="address.postalCode" :disabled="(currentUser && currentUser.OrganizationId) || isSavingAddress"
                                               @change="validatePostalCode()">

                                        <InputFieldError attribute="RTN_LNA_POSTAL" :errors="errorsAddress"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="city">{{ $store.state.translations.attributeLabels['RTN_LNA_CITY'] }} *</label>
                                        <input id="city" type="text" class="form-control" v-model="address.city" :disabled="(currentUser && currentUser.OrganizationId) || isSavingAddress"
                                               @change="saveAddress()">

                                        <InputFieldError attribute="RTN_LNA_CITY" :errors="errorsAddress"/>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4" v-if="Object.keys(administrativeAreas).length > 0">
                                    <div class="form-group w-100">
                                        <label for="administrative-area">{{ $store.state.translations.translations[formConfig.administrativeArea.label] }}</label>

                                        <model-list-select
                                            class="mb-5 form-control"
                                            id="administrative-area"
                                            :list="administrativeAreas"
                                            option-value="code"
                                            option-text="label"
                                            v-model="address.administrativeArea"
                                            :placeholder="$store.state.translations.translations['Select your {administrativeArea}'].replace('{administrativeArea}', $store.state.translations.translations[administrativeAreaLabel])"
                                            required
                                        >
                                        </model-list-select>
                                    </div>
                                </div>

                                <div class="col-md-6 ph-lg-4">
                                    <div class="form-group w-100">
                                        <label for="country">{{ $store.state.translations.attributeLabels['RTN_LNA_COUNTY'] }}</label>

                                        <select class="form-control" id="country" disabled="disabled">
                                            <option value="">{{ address.country }}</option>
                                        </select>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <div class="col-12">
                                    <div class="custom-table">
                                        <div class="custom-table-body">
                                            <div class="custom-table-body-row d-flex justify-content-between align-items-center">
                                                <div class="custom-table-td w-auto">
                                                    {{ address.addressLine1 }}
                                                </div>

                                                <div class="custom-table-td w-auto" v-if="address.addressLine2">
                                                    {{ address.addressLine2 }}
                                                </div>

                                                <div class="custom-table-td w-auto" v-if="address.postalCode">
                                                    {{ address.postalCode }}
                                                </div>

                                                <div class="custom-table-td w-auto">
                                                    {{ address.city }}
                                                </div>

                                                <div class="custom-table-td w-auto">
                                                    {{ address.country }}
                                                </div>

                                                <div class="custom-table-td w-auto" v-if="address.administrativeArea">
                                                    {{ address.administrativeArea }}
                                                </div>

                                                <div class="custom-table-td w-auto">
                                                    <button class="btn sm btn-primary" @click.prevent="showAddressForm = true">
                                                        {{ $store.state.translations.translations['Edit'] }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <InputFieldError attribute="general" :errors="errorsAddress"/>

                        <div class="row" v-if="currentUser && !currentUser.OrganizationId">
                            <div class="col-12 my-4">
                                <div class="d-lg-flex flex-wrap">
                                    <label class="checkbox-container me-3">
                                        <input type="checkbox" class="form-control" v-model="saveDefaultAddress" :disabled="isSavingAddress">

                                        <span class="checkmark"></span>

                                        {{ $store.state.translations.translations['Save as default address'] }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

              <!-- End Your address -->

              <!-- Start Return service -->

              <template v-if="useSettlementProposals">
                <div class="container sm py-4">
                        <span class="label-title">
                            {{ $store.state.translations.translations['Return service'] }}
                        </span>
                </div>

                <template v-if="showSettlementProposalWarning">
                  <div class="container sm">
                    <div class="row">
                      <div class="col-12">
                        {{ $store.state.translations.translations['Please enter your address in order to view the transport services.'] }}
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <SettlementProposalSelect @has-saved-settlement-proposal="afterSelectingSettlementProposal" />
                </template>
              </template>

              <!-- End Return service -->

              <!-- Start Package type -->

              <div class="container sm py-4">
                    <span class="label-title">
                        {{ $store.state.translations.translations['Package type'] }}
                    </span>
                </div>

                <div class="container sm" v-if="showPackageTypeWarning">
                    <div class="row">
                        <div class="col-12">
                            <!-- The sentence used depends on the value of useSettlementProposals. A settlement proposal is optional -->
                            {{ $store.state.translations.translations['Please enter your address and select a settlement proposal before you can select your parcel type.'] }}
                        </div>
                    </div>
                </div>

                <div class="container sm" v-else-if="isLoadingParcelTypes">
                    <div class="row">
                        <div class="col-md-6 pe-lg-4">
                            <div class="form-group w-100">
                                {{ $store.state.translations.translations['Loading package types...'] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container sm" v-else-if="!hasPackageTypes">
                    <div class="row">
                        <div class="col-md-6 pe-lg-4">
                            <div class="form-group w-100">
                                {{ $store.state.translations.translations['No package types available'] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container sm pb-md-5" v-else-if="showPackageTypeOptions">
                    <template v-if="isLoadingShipmentUnits || isLoadingMeasurementUnits">
                        {{ $store.state.translations.translations['Loading packages...'] }}
                    </template>

                    <template v-else-if="!hasMetricUnits && !hasImperialUnits">
                        {{ $store.state.translations.translations['Unable to retrieve measurement units.'] }}
                    </template>

                    <template v-else>
                        <div class="row" v-if="hasMetricUnits && hasImperialUnits">
                            <div class="col-12">
                                <p>
                                    {{ $store.state.translations.translations['Measurement system:'] }}

                                    <a href="#" @click.prevent="setSelectedMeasurementUnit('metric')" :class="selectedMeasurementUnit == 'CM' ? 'selected' : ''" v-if="hasMetricUnits">
                                        {{ $store.state.translations.translations['Metric'] }}
                                    </a>

                                    |

                                    <a href="#" @click.prevent="setSelectedMeasurementUnit('imperial')" :class="selectedMeasurementUnit == 'IN' ? 'selected' : ''" v-if="hasImperialUnits">
                                        {{ $store.state.translations.translations['Imperial'] }}
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="custom-table" v-if="Object.keys(shipmentUnits).length > 0">
                                    <div class="custom-table-head">
                                        <div class="custom-table-head-cell two mobile-hidden">
                                            {{ $store.state.translations.attributeLabels['RTN_PAT_TYPE'] }}
                                        </div>

                                        <div class="custom-table-head-cell third mobile-hidden">
                                            {{ $store.state.translations.attributeLabels['Dimensions'] }}
                                        </div>

                                        <div class="custom-table-head-cell mobile-hidden">
                                            {{ $store.state.translations.attributeLabels['Weight'] }}
                                        </div>
                                    </div>

                                    <div class="custom-table-body" v-for="shipmentUnit in shipmentUnits" :key="shipmentUnit.RTN_SPU_ID">
                                        <div class="custom-table-body-row">
                                            <template v-if="shipmentUnitsBeingDeleted.indexOf(shipmentUnit.RTN_SPU_ID) != -1">
                                                <div class="custom-table-td ms-3">
                                                    {{ $store.state.translations.translations['Deleting package...'] }}
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['RTN_PAT_TYPE'] }}
                                                </div>

                                                <div class="custom-table-th-mobile"></div>

                                                <div class="custom-table-td two">
                                                    {{ getParcelTypeLabel(shipmentUnit.RTN_PAT_ID) }}
                                                </div>

                                                <div class="custom-table-th-mobile"></div>

                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['Dimensions'] }}
                                                </div>

                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['Weight'] }}
                                                </div>

                                                <div class="custom-table-td third">
                                                    <template v-if="selectedMeasurementUnit == 'CM'">
                                                        {{ shipmentUnit.RTN_SPU_PARCEL_LENGTH }} &times; {{ shipmentUnit.RTN_SPU_PARCEL_WIDTH }} &times; {{ shipmentUnit.RTN_SPU_PARCEL_HEIGHT }} {{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }}
                                                    </template>

                                                    <template v-else-if="selectedMeasurementUnit == 'IN'">
                                                        {{ shipmentUnit.RTN_SPU_PARCEL_IMPERIAL_LENGTH }} &times; {{ shipmentUnit.RTN_SPU_PARCEL_IMPERIAL_WIDTH }} &times; {{ shipmentUnit.RTN_SPU_PARCEL_IMPERIAL_HEIGHT }} {{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }}
                                                    </template>
                                                </div>

                                                <div class="custom-table-td">
                                                    <template v-if="selectedWeightUnit == 'KG'">
                                                        {{ shipmentUnit.RTN_SPU_WEIGHT }} {{ $store.state.translations.translations[parcelWeightUnitOptions[selectedWeightUnit]] }}
                                                    </template>

                                                    <template v-else-if="selectedWeightUnit == 'LB'">
                                                        {{ shipmentUnit.RTN_SPU_IMPERIAL_WEIGHT }} {{ $store.state.translations.translations[parcelWeightUnitOptions[selectedWeightUnit]] }}
                                                    </template>
                                                </div>

                                                <div class="custom-table-th-mobile"></div>

                                                <div class="custom-table-td end">
                                                    <button class="btn sm btn-primary" @click.prevent="deleteShipmentUnit(shipmentUnit.RTN_SPU_ID)">
                                                        &times;
                                                    </button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="isAddingParcel">
                            <div class="col-12">
                                <div class="custom-table" :class="parcel.type ? '' : 'mt-3'">
                                    <div class="custom-table-head">
                                        <div class="custom-table-head-cell two mobile-hidden">
                                            {{ $store.state.translations.attributeLabels['RTN_PAT_TYPE'] }}
                                        </div>

                                        <template v-if="parcel.type">
                                            <div class="custom-table-head-cell smaller mobile-hidden">
                                                {{ $store.state.translations.attributeLabels['Length'] }}

                                                <div>
                                                    ({{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }})
                                                </div>
                                            </div>

                                            <div class="custom-table-head-cell smaller mobile-hidden">
                                                {{ $store.state.translations.attributeLabels['Width'] }}

                                                <div>
                                                    ({{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }})
                                                </div>
                                            </div>

                                            <div class="custom-table-head-cell smaller mobile-hidden">
                                                {{ $store.state.translations.attributeLabels['Height'] }}

                                                <div>
                                                    ({{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }})
                                                </div>
                                            </div>

                                            <div class="custom-table-head-cell smaller mobile-hidden">
                                                {{ $store.state.translations.attributeLabels['Weight'] }}

                                                <div>
                                                    ({{ $store.state.translations.translations[parcelWeightUnitOptions[selectedWeightUnit]] }})
                                                </div>
                                            </div>
                                        </template>
                                    </div>

                                    <div class="custom-table-body">
                                        <div class="custom-table-body-row">
                                            <template v-if="isSavingShipmentUnit">
                                                <div class="custom-table-td ms-3">
                                                    {{ $store.state.translations.translations['Saving package...'] }}
                                                </div>
                                            </template>

                                            <template v-else-if="!parcel.type">
                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['RTN_PAT_TYPE'] }}
                                                </div>

                                                <div class="custom-table-td w-100">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-11">
                                                            <v-select :options="parcelTypes" v-model="parcel.type" :clearable="false" :filterable="false" :searchable="false" label="RTN_PAT_LABEL" @option:selected="prepopulateParcelTypeData()">
                                                                <template v-slot:option="option">
                                                                    <span>{{ option.RTN_PAT_LABEL }}</span>
                                                                    <span class="option-description">
                                                                        |

                                                                        <template v-if="selectedMeasurementUnit === 'CM'">
                                                                            {{ option.RTN_PAT_METRIC_LENGTH ? option.RTN_PAT_METRIC_LENGTH : '-' }}
                                                                            &times;
                                                                            {{ option.RTN_PAT_METRIC_WIDTH ? option.RTN_PAT_METRIC_WIDTH : '-' }}
                                                                            &times;
                                                                            {{ option.RTN_PAT_METRIC_HEIGHT ? option.RTN_PAT_METRIC_HEIGHT : '-' }}
                                                                        </template>

                                                                        <template v-else>
                                                                            {{ option.RTN_PAT_IMPERIAL_LENGTH ? option.RTN_PAT_IMPERIAL_LENGTH : '-' }}
                                                                            &times;
                                                                            {{ option.RTN_PAT_IMPERIAL_WIDTH ? option.RTN_PAT_IMPERIAL_WIDTH : '-' }}
                                                                            &times;
                                                                            {{ option.RTN_PAT_IMPERIAL_HEIGHT ? option.RTN_PAT_IMPERIAL_HEIGHT : '-' }}
                                                                        </template>

                                                                        {{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }}
                                                                    </span>
                                                                </template>

                                                                <template v-slot:selected-option="option">
                                                                    <span>{{ option.RTN_PAT_LABEL }}</span>
                                                                    <span class="option-description">| Dimensions</span>
                                                                </template>
                                                            </v-select>
                                                        </div>

                                                        <div class="col text-end text-lg-left mt-3 me-3 m-lg-0">
                                                            <button class="btn sm btn-primary ms-2 mt-1" @click.prevent="hideAddParcelRow()">
                                                                &times;
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['RTN_PAT_TYPE'] }}
                                                </div>

                                                <div class="custom-table-th-mobile"></div>

                                                <div class="custom-table-td two">
                                                    <v-select :options="parcelTypes" v-model="parcel.type" :clearable="false"
                                                              :filterable="false" :searchable="false" label="RTN_PAT_LABEL" @option:selected="prepopulateParcelTypeData()"></v-select>

                                                    <InputFieldError attribute="type" :errors="errorsShipmentUnit" v-if="Object.keys(errorsShipmentUnit).length > 0"/>
                                                </div>

                                                <div class="custom-table-th-mobile"></div>

                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['Length'] }}

                                                    <div>
                                                        ({{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }})
                                                    </div>
                                                </div>

                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['Width'] }}

                                                    <div>
                                                        ({{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }})
                                                    </div>
                                                </div>

                                                <div class="custom-table-td smaller">
                                                    <input type="number" class="form-control" v-model="parcel.length" :disabled="parcel.type.RTN_PAT_METRIC_LENGTH || parcel.type.RTN_PAT_IMPERIAL_LENGTH">

                                                    <InputFieldError :attribute="['length', 'RTN_SPU_PARCEL_LENGTH']" :errors="errorsShipmentUnit" v-if="Object.keys(errorsShipmentUnit).length > 0"/>
                                                </div>

                                                <div class="custom-table-td smaller">
                                                    <input type="number" class="form-control" v-model="parcel.width" :disabled="parcel.type.RTN_PAT_METRIC_WIDTH || parcel.type.RTN_PAT_IMPERIAL_WIDTH">

                                                    <InputFieldError :attribute="['width', 'RTN_SPU_PARCEL_WIDTH']" :errors="errorsShipmentUnit" v-if="Object.keys(errorsShipmentUnit).length > 0"/>
                                                </div>

                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['Height'] }}

                                                    <div>
                                                        ({{ $store.state.translations.translations[parcelMeasurementUnitOptions[selectedMeasurementUnit]] }})
                                                    </div>
                                                </div>

                                                <div class="custom-table-th-mobile">
                                                    {{ $store.state.translations.attributeLabels['Weight'] }}

                                                    <div>
                                                        ({{ $store.state.translations.translations[parcelWeightUnitOptions[selectedWeightUnit]] }})
                                                    </div>
                                                </div>

                                                <div class="custom-table-td smaller">
                                                    <input type="number" class="form-control" v-model="parcel.height" :disabled="parcel.type.RTN_PAT_METRIC_HEIGHT || parcel.type.RTN_PAT_IMPERIAL_HEIGHT">

                                                    <InputFieldError :attribute="['height', 'RTN_SPU_PARCEL_HEIGHT']" :errors="errorsShipmentUnit" v-if="Object.keys(errorsShipmentUnit).length > 0"/>
                                                </div>

                                                <div class="custom-table-td smaller">
                                                    <input type="number" class="form-control" v-model="parcel.weight" :disabled="parcel.type.RTN_PAT_METRIC_WEIGHT || parcel.type.RTN_PAT_IMPERIAL_WEIGHT">

                                                    <InputFieldError :attribute="['weight', 'RTN_SPU_WEIGHT']" :errors="errorsShipmentUnit" v-if="Object.keys(errorsShipmentUnit).length > 0"/>
                                                </div>

                                                <div class="custom-table-th-mobile"></div>

                                                <div class="custom-table-td end">
                                                    <button class="btn sm btn-primary" @click.prevent="saveShipmentUnit()">
                                                        {{ $store.state.translations.translations['Save'] }}
                                                    </button>

                                                    <button class="btn sm btn-primary ms-3" @click.prevent="hideAddParcelRow()">
                                                        &times;
                                                    </button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <button type="button" name="button" class="btn btn-primary m-1 m-md-3" @click="showAddParcelRow()"
                                        v-if="!isAddingParcel && (multiParcelAllowed || (!multiParcelAllowed && Object.keys(shipmentUnits).length === 0))"
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    </template>

                    <InputFieldError attribute="general" :errors="errorsShipmentUnit" v-if="Object.keys(errorsShipmentUnit).length > 0" />
                </div>

                <!-- End Package type -->

                <!-- Start Carrier service -->

                <div class="container sm py-4">
                    <span class="label-title">
                        {{ $store.state.translations.translations['Carrier service'] }}
                    </span>
                </div>

                <div class="container sm" v-if="Object.keys(errorsCarrier).length > 0">
                    <div class="row mb-3">
                        <div class="col">
                            <InputFieldError :attribute="['carrier', 'shipment']" :errors="errorsCarrier"/>
                        </div>
                    </div>
                </div>

                <div class="container sm" v-if="useSettlementProposals && !hasSelectedSettlementProposal">
                    <div class="row">
                        <div class="col-12">
                            {{ $store.state.translations.translations['Please select a settlement option in order to continue.'] }}
                        </div>
                    </div>
                </div>

                <div class="container sm" v-else-if="!hasSavedAddress || Object.keys(shipmentUnits).length === 0">
                    <div class="row">
                        <div class="col-12">
                            {{ $store.state.translations.translations['Please select your package type in order to view the available carrier services'] }}
                        </div>
                    </div>
                </div>

                <div class="container sm" v-else-if="isLoadingCarriers">
                    <div class="row">
                        <div class="col-md-6 pe-lg-4">
                            <div class="form-group w-100">
                                {{ $store.state.translations.translations['Loading carriers...'] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container sm" v-else-if="filteredCarriers.length === 0">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group w-100">
                                {{ $store.state.translations.translations['There are no carriers available which support the selected package types'] }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container sm pb-md-5" v-else>
                    <div class="shipping-methods overlay-container">
                        <div class="overlay" v-if="isSavingCarrier">
                            <div class="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
                        </div>

                        <div class="row" :class="isSavingCarrier ? 'opacity-50' : ''">
                            <div class="shipping-method col-12 col-lg-6" v-for="carrier in filteredCarriers" :key="carrier.RTN_CRR_ID" @click="selectCarrier(carrier)">
                                <div class="shipping-block" ref="carrierOption" v-bind:class="{ active: carrier.RTN_CRR_ID == selectedCarrierId }" :style="{'height': carrierOptionHeight}">
                                    <div class="row">
                                        <div class="col-4" style="min-height:135px;">
                                            <template v-if="carrierParams[carrier.RTN_CRR_ID].logo">
                                                <template v-if="carrierParams[carrier.RTN_CRR_ID].logo.charAt(0) == '/'">
                                                    <img v-bind:src="$store.state.baseUrl + carrierParams[carrier.RTN_CRR_ID].logo" @load="calculateCarrierOptionHeight()" class="img-fluid" alt="">
                                                </template>

                                                <template v-else>
                                                    <img v-bind:src="carrierParams[carrier.RTN_CRR_ID].logo" @load="calculateCarrierOptionHeight()" class="img-fluid" alt="">
                                                </template>
                                            </template>
                                        </div>

                                        <div class="col-8">
                                            <h3>{{ carrier.RTN_CRR_LABEL}}</h3>
                                            <span>{{ carrier.RTN_CRR_DESCRIPTION }}</span>

                                            <template v-if="1==0">
                                                <p v-if="carrierParams[carrier.RTN_CRR_ID].dropoffInfoLink">
                                                    <a :href="carrierParams[carrier.RTN_CRR_ID].dropoffInfoLink"
                                                       target="_blank" class="btn btn-primary my-2">
                                                        {{ $store.state.translations.translations['Dropoff locations'] }}
                                                    </a>
                                                </p>
                                            </template>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <ul class="carrier-details">
                                                <li v-if="!useSettlementProposals && carrierParams[carrier.RTN_CRR_ID].customerCost && carrierParams[carrier.RTN_CRR_ID].customerCostCurrency && carrierParams[carrier.RTN_CRR_ID].customerCost > 0" class="mt-3">
                                                    <strong>
                                                        {{ carrierParams[carrier.RTN_CRR_ID].customerCost }}
                                                        {{ carrierParams[carrier.RTN_CRR_ID].customerCostCurrency }}
                                                    </strong>

                                                    | {{ $store.state.translations.translations['These costs will be deducted from your refund.'] }}
                                                </li>

                                                <li v-if="carrierParams[carrier.RTN_CRR_ID].labelCost && carrierParams[carrier.RTN_CRR_ID].customerCostCurrency && carrierParams[carrier.RTN_CRR_ID].labelCost > 0" class="mt-3">
                                                    <strong>
                                                        {{ carrierParams[carrier.RTN_CRR_ID].labelCost }}
                                                        {{ carrierParams[carrier.RTN_CRR_ID].customerCostCurrency }}
                                                    </strong>

                                                    | {{ $store.state.translations.translations['You have to buy a shipping label.'] }}
                                                </li>

                                                <li v-if="carrierParams[carrier.RTN_CRR_ID].noPrinterNeeded === '1' || carrierParams[carrier.RTN_CRR_ID].noPrinterNeeded === 1" class="mt-3">
                                                    {{ $store.state.translations.translations['No printer needed'] }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><!-- shipping-methods -->
                </div>

              <!-- End Carrier service -->

              <div class="container">
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-center"
                         v-if="Object.keys(errorsAddress).length !== 0 || Object.keys(errorsCarrier).length !== 0">
                        <p class="error-text">
                            {{ $store.state.translations.translations['Some fields require your attention.'] }}
                        </p>
                    </div>

                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-center" v-if="Object.keys(errorsPayment).length > 0">
                        <div class="row mb-3">
                            <div class="col">
                                <InputFieldError attribute="global" :errors="errorsPayment"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-center" v-if="selectedCarrier && selectedCarrier.RTN_CRR_LABEL && Object.keys(shipmentUnits).length > 0">
                        <p>
                            {{ $store.state.translations.translations['You\'ve selected {shipmentUnitCount} package(s) with shipping option {selectedCarrierLabel}.'].replace('{shipmentUnitCount}', Object.keys(shipmentUnits).length).replace('{selectedCarrierLabel}', selectedCarrier.RTN_CRR_LABEL) }}
                        </p>

                        <p v-if="selectedCarrierRequiresLabelPayment">
                            {{ $store.state.translations.translations['For this carrier you have to buy a shipping label. You will be redirected to the payment page.'].replace('{labelCost}', carrierParams[selectedCarrier.RTN_CRR_ID].labelCost) }}
                        </p>
                    </div>

                    <div class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center pt-3 pt-md-4 mb-5">
                        <button type="button" name="button" class="btn md btn-secondary m-3" @click="previous()">
                            {{ $store.state.translations.translations['Back'] }}
                        </button>

                        <button type="button" name="button" class="btn md btn-primary m-1 m-md-3" @click="next()"
                                v-if="canCreateShipment">
                            {{ $store.state.translations.translations['Create shipment'] }}
                        </button>
                    </div>
                </div>
            </div>
        </main>

        <SspFooter></SspFooter>
    </div>
</template>

<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ShowProductDetails from '@/components/_ShowProductDetails.vue';
    import SettlementProposalSelect from '@/components/_SettlementProposalSelect.vue';
    import {ModelListSelect} from 'vue-search-select';
    import vSelect from 'vue-select';
    import _ from 'lodash';

    export default {
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'InputFieldError': InputFieldError,
            'ShowProductDetails': ShowProductDetails,
            'SettlementProposalSelect': SettlementProposalSelect,
            'ModelListSelect': ModelListSelect,
            'v-select': vSelect,
        },
        data() {
            return {
                errorsAddress: {},
                errorsCarrier: {},
                errorsShipmentUnit: {},
                errorsPayment: {},
                returnId: this.$route.query.returnId,
                merchantSetupId: this.$route.query.merchantSetupId,
                orderId: this.$route.query.orderId,
                appBaseUrl: this.$store.state.portalUrl,
                addressForm: null,
                isSavingAddress: false,
                hasSavedAddress: false,
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    postalCode: '',
                    city: '',
                    country: '',
                    countryCode: '',
                    administrativeArea: '',
                },
                selectedCountry: null,
                saveDefaultAddress: false,
                carriers: [],
                predefinedCarriers: [],
                carrierParams: [],
                isSavingCarrier: false,
                selectedCarrier: '',
                selectedCarrierId: '',
                formConfig: {
                    administrativeArea: {label: ''},
                    postalCode: {label: ''},
                },
                isLoadingForm: true,
                isLoadingCarriers: false,
                isLoadingParcelTypes: false,
                isSubmittingForm: false,
                isLoadingShipmentUnits: false,
                isLoadingMeasurementUnits: false,
                isLoadingOrganizationLocations: false,
                parcelTypes: [],
                mcpParams: null,
                multiParcelAllowed: false,
                parcelTypeMinimum: 0,
                parcelTypeMaximum: 20,
                currentUser: null,
                organizationLocations: null,
                selectedOrganizationLocation: null,
                carrierOptionHeight: null,
                selectedMeasurementUnit: '',
                selectedWeightUnit: '',
                administrativeAreas: [],
                administrativeAreaLabel: '',
                administrativeAreaIsLoaded: false,
                parcel: {
                    type: '',
                    length: '',
                    width: '',
                    height: '',
                    weight: '',
                },
                parcelMeasurementUnitOptions: [],
                parcelWeightUnitOptions: [],
                shipmentUnits: {},
                shipmentUnitsBeingDeleted: [],
                isAddingParcel: false,
                isSavingShipmentUnit: false,
                checkoutUrl: null,
                useSettlementProposals: false,
                hasSelectedSettlementProposal: false,
                showAddressForm: true,
            };
        },
        created() {
            let addressFormConfigUrl = new URL(this.appBaseUrl + 'shipment/getAddressFormConfig');
            addressFormConfigUrl.searchParams.set('returnId', this.returnId);

            let formValuesUrl = new URL(this.appBaseUrl + 'shipment/getAddressValues');
            formValuesUrl.searchParams.set('returnId', this.returnId);
			formValuesUrl.searchParams.set('orderId', this.orderId);

            let getUserUrl = new URL(this.appBaseUrl + 'user/getCurrentUser');

            let addressFormConfigGet = this.$http.get(addressFormConfigUrl.href);
            let formValuesGet = this.$http.get(formValuesUrl.href);
            let userData = this.$http.get(getUserUrl.href);

            const getMerchantSetupParameters = this.$store.dispatch('mcpModule/getAll');

            Promise.all([addressFormConfigGet, formValuesGet, userData, getMerchantSetupParameters]).then((data) => {
                this.formConfig = data[0].data.data.formConfig;

                this.address.country = data[1].data.data.address.country;
                this.address.countryCode = data[1].data.data.address.countryCode;
                this.address.administrativeArea = data[1].data.data.address.administrativeArea;

                this.getMeasurementSystemByCountryCode();

                /**
                 * If the selected country of the return is the same as the country of the user's default address,
                 * populate the address fields with the user's default address.
                 */
                if (data[2].data.data.address && data[2].data.data.address.Country === this.address.countryCode) {
                    this.address.addressLine1 = data[2].data.data.address.AddressLine1;
                    this.address.addressLine2 = data[2].data.data.address.AddressLine2;
                    this.address.postalCode = data[2].data.data.address.Postal;
                    this.address.city = data[2].data.data.address.City;
                }

                if (data[2].data.data.user) {
                    this.currentUser = data[2].data.data.user;
                }

				/**
				 * populate if order connector (not for business users)
				 */
				if (!this.currentUser || !this.currentUser.OrganizationId) {
                    if (data[1].data.data.address.addressLine1)
                        this.address.addressLine1 = data[1].data.data.address.addressLine1;
                    if (data[1].data.data.address.addressLine2)
                        this.address.addressLine2 = data[1].data.data.address.addressLine2;
                    if (data[1].data.data.address.postalCode)
                        this.address.postalCode = data[1].data.data.address.postalCode;
                    if (data[1].data.data.address.city)
                        this.address.city = data[1].data.data.address.city;
                }

                this.mcpParams = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].parameters;
                this.multiParcelAllowed = this.mcpParams['allowMultipleParcels'];
                this.allowPackageMerge = this.mcpParams['allowPackageMerge'];
                this.useSettlementProposals = this.mcpParams['useSettlementProposals'];

                this.showAddressForm = !this.isAddressFormComplete();

                this.saveAddress();

                this.isLoadingForm = false;

                // Ophalen van carriers uiteindelijk niet hier doen, dit is een temp fix om te zorgen dat het
                // andere calls niet gaat vertragen.
                this.getCarriers();

                this.getAdministrativeAreas();
            });

            this.getPaymentStatus();
            this.getMeasurementUnits();
            this.getShipmentUnits();
            this.getParcelTypes();
            this.getOrganizationLocations();
        },
        methods: {
            saveAddress() {
                if (!this.isAddressFormComplete()) {
                    return false;
                }

                this.errorsAddress = {};
                this.isSavingAddress = true;

                if (!this.validateAddressInput()) {
                    this.isSavingAddress = false;
                    return false;
                }

                let url = new URL(this.$store.state.portalUrl + 'shipment/saveAddress');
                url.searchParams.set('returnId', this.returnId);

                let addressAttributes = {
                    AddressLine1: this.address.addressLine1,
                    AddressLine2: this.address.addressLine2,
                    Postal: this.address.postalCode,
                    City: this.address.city,
                    Country: this.address.countryCode,
                    AdministrativeArea: this.address.administrativeArea,
                };

                let params = new URLSearchParams();

                _.forOwn(addressAttributes, function (value, key) {
                    if (value) {
                        params.append('address[' + key + ']', value);
                    }
                });

                let addressPost = this.$http.post(url.href, params);

                addressPost.then((data) => {
                    this.isSavingAddress = false;

                    if (this.saveDefaultAddress) {
                        let saveUserUrl = new URL(this.$store.state.portalUrl + 'user/save');
                        let saveUserParams = new URLSearchParams();

                        _.forOwn(addressAttributes, function (value, key) {
                            if (value) {
                                saveUserParams.append('address[' + key + ']', value);
                            }
                        });

                        this.$http.post(saveUserUrl.href, saveUserParams).then((data) => {
                            this.userErrors = data.data.data.userErrors;
                            this.addressErrors = data.data.data.addressErrors;
                        });
                    }

                    this.unsetSelectedCarrier();
                    this.carriers = [];

                    if (data.data.data.errorsAddress) {
                        this.errorsAddress = data.data.data.errorsAddress;
                        this.showAddressForm = true;
                    } else {
                        this.getCarriers();
                        this.hasSavedAddress = true;
                        this.showAddressForm = false;
                        this.autoSelectParcelType();
                    }
                });
            },
            selectCarrier(carrier) {
                this.isSavingCarrier = true;

                this.selectedCarrier = carrier;
                this.selectedCarrierId = carrier.RTN_CRR_ID;

                let selectCarrierUrl = new URL(this.$store.state.portalUrl + 'shipment/selectCarrier');
                selectCarrierUrl.searchParams.set('returnId', this.returnId);

                let params = new URLSearchParams();

                if (this.selectedCarrier.RTN_CRR_ID) {
                    params.append('carrierId', this.selectedCarrier.RTN_CRR_ID);
                }

                let carrierPost = this.$http.post(selectCarrierUrl.href, params);

                carrierPost.then((data) => {
                    this.checkoutUrl = null;
                    this.isSavingCarrier = false;

                    if (data.data.data.errorsCarrier) {
                        this.errorsCarrier['carrier'] = data.data.data.errorsCarrier;
                    } else if (data.data.data.errorsPayment) {
                        this.errorsPayment = {global: data.data.data.errorsPayment};
                    } else if (data.data.data.checkoutUrl) {
                        this.checkoutUrl = data.data.data.checkoutUrl;
                    }
                });
            },
            autoSelectCarrier(filteredCarriers) {
                if (this.hasSavedAddress && Object.keys(this.shipmentUnits).length > 0 && filteredCarriers.length === 1 && !this.isSavingCarrier && !this.selectedCarrier) {
                    this.selectCarrier(filteredCarriers[0]);
                }
            },
            unsetSelectedCarrier() {
                this.selectedCarrier = '';
                this.selectedCarrierId = '';
            },
            next() {
                if (!this.validateAddressInput() || Object.keys(this.errorsAddress).length > 0 || Object.keys(this.errorsCarrier).length > 0) {
                    return false;
                }

                if (this.checkoutUrl) {
                    window.location.href = this.checkoutUrl;
                } else {
                    this.$router.push({
                        name: 'ShipSubmit',
                        query: {
                            returnId: this.returnId,
                        }
                    });
                }
            },
            previous() {
                this.$router.push({
                    name: 'Ship'
                });
            },
            validateInput() {
                let result = true;

                if (!this.address.addressLine1) {
                    this.errorsAddress['RTN_LNA_ADDRESS_LINE_1'] = this.$store.state.translations.translations['This field is required.'];
                    result = false;
                }

                if (!this.address.postalCode && this.formConfig.postalCode.label) {
                    this.errorsAddress['RTN_LNA_POSTAL'] = this.$store.state.translations.translations['This field is required.'];
                    result = false;
                }

                if (!this.address.city) {
                    this.errorsAddress['RTN_LNA_CITY'] = this.$store.state.translations.translations['This field is required.'];
                    result = false;
                }

                if (!this.selectedCarrierId) {
                    this.errorsCarrier['carrier'] = this.$store.state.translations.translations['Select a carrier.'];
                    result = false;
                }

                return result;
            },
            validateAddressInput() {
                let result = true;

                if (!this.address.addressLine1) {
                    this.errorsAddress['RTN_LNA_ADDRESS_LINE_1'] = this.$store.state.translations.translations['This field is required.'];
                    result = false;
                }

                if (!this.address.postalCode && this.formConfig.postalCode.label) {
                    this.errorsAddress['RTN_LNA_POSTAL'] = this.$store.state.translations.translations['This field is required.'];
                    result = false;
                }

                if (!this.address.city) {
                    this.errorsAddress['RTN_LNA_CITY'] = this.$store.state.translations.translations['This field is required.'];
                    result = false;
                }

                return result;
            },
            getAdministrativeAreas() {
                let administrativeAreasUrl = new URL(this.$store.state.portalUrl + 'return/getAdministrativeAreas');
                administrativeAreasUrl.searchParams.set('countryCode', this.address.countryCode);
                this.$http.get(administrativeAreasUrl.href).then((data) => {
                    this.isLoadingAdministrativeAreas = false;
                    if (data.data.data.administrativeAreas) {
                        this.administrativeAreas = _.toPairs(data.data.data.administrativeAreas).map(a => {
                            return {code: a[0], label: a[1]};
                        });
                    }

                    if (Object.keys(this.administrativeAreas).length !== 0) {
                        let administrativeAreaLabelUrl = new URL(this.$store.state.portalUrl + 'return/getAdministrativeAreaLabel');
                        administrativeAreaLabelUrl.searchParams.set('countryCode', this.address.countryCode);

                        this.$http.get(administrativeAreaLabelUrl.href).then((data) => {
                            this.administrativeAreaLabel = data.data.data.administrativeAreaLabel;
                        });
                    }
                    this.administrativeAreaIsLoaded = true;
                });
            },
            getParcelTypes() {
                this.isLoadingParcelTypes = true;
                const getParcelTypesUrl = new URL(this.appBaseUrl + 'merchantSetup/getParcelTypes');
                getParcelTypesUrl.searchParams.set('merchantSetupId', this.merchantSetupId);

                this.$http.get(getParcelTypesUrl.href).then((data) => {
                    this.isLoadingParcelTypes = false;
                    this.parcelTypes = data.data.data.parcelTypes;
                    this.autoSelectParcelType();
                });
            },
            autoSelectParcelType() {
                // Auto-select the parcel type if there is only 1 available.
                if (this.hasSavedAddress && this.parcelTypes.length === 1 && Object.keys(this.shipmentUnits).length === 0) {
                    this.parcel.type = this.parcelTypes[0];
                    this.prepopulateParcelTypeData();
                }
            },
            getParcelTypeLabel(parcelTypeId) {
                const parcelType = _.find(this.parcelTypes, ['RTN_PAT_ID', parcelTypeId]);

                if (parcelType) {
                    return parcelType.RTN_PAT_LABEL;
                }

                return null;
            },
            getParcelTypeAllowedCarrierIds(parcelTypeId) {
                const parcelType = _.find(this.parcelTypes, ['RTN_PAT_ID', parcelTypeId]);

                if (parcelType) {
                    return parcelType.RTN_PAT_CARRIER_ALLOCATION_JSON;
                }

                return null;
            },
            getOrganizationLocations() {
                this.isLoadingOrganizationLocations = true;
                let url = new URL(this.$store.state.portalUrl + 'user/getOrganizationLocations');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingOrganizationLocations = false;

                    if (data.data.data.organizationLocations) {
                        this.organizationLocations = data.data.data.organizationLocations;
                    }
                });
            },
            populateAddressFields(location) {
                this.address.addressLine1 = location.AddressLine1;
                this.address.addressLine2 = location.AddressLine2;
                this.address.postalCode = location.Postal;
                this.address.city = location.City;
                this.address.administrativeArea = location.AdministrativeArea;
            },
            calculateCarrierOptionHeight() {
                this.carrierOptionHeight = null;

                this.$nextTick(() => {
                    if (this.$refs.carrierOption) {
                        let scrollHeight = 0;

                        for (let i = 0; i < this.$refs.carrierOption.length; i++) {
                            if (this.$refs.carrierOption[i].offsetHeight > scrollHeight) {
                                scrollHeight = this.$refs.carrierOption[i].offsetHeight;
                            }
                        }

                        this.carrierOptionHeight = scrollHeight + 'px';
                    }
                });
            },
            showAddParcelRow() {
                this.isAddingParcel = true;
            },
            hideAddParcelRow() {
                this.parcel.type = '';
                this.parcel.length = '';
                this.parcel.width = '';
                this.parcel.height = '';
                this.parcel.weight = '';
                this.isAddingParcel = false;
                this.errorsShipmentUnit = {};
            },
            prepopulateParcelTypeData() {
                this.errorsShipmentUnit = {};

                if (this.selectedMeasurementUnit === 'CM') {
                    this.parcel.length = this.parcel.type.RTN_PAT_METRIC_LENGTH ? this.parcel.type.RTN_PAT_METRIC_LENGTH : '';
                    this.parcel.width = this.parcel.type.RTN_PAT_METRIC_WIDTH ? this.parcel.type.RTN_PAT_METRIC_WIDTH : '';
                    this.parcel.height = this.parcel.type.RTN_PAT_METRIC_HEIGHT ? this.parcel.type.RTN_PAT_METRIC_HEIGHT : '';
                } else {
                    this.parcel.length = this.parcel.type.RTN_PAT_IMPERIAL_LENGTH ? this.parcel.type.RTN_PAT_IMPERIAL_LENGTH : '';
                    this.parcel.width = this.parcel.type.RTN_PAT_IMPERIAL_WIDTH ? this.parcel.type.RTN_PAT_IMPERIAL_WIDTH : '';
                    this.parcel.height = this.parcel.type.RTN_PAT_IMPERIAL_HEIGHT ? this.parcel.type.RTN_PAT_IMPERIAL_HEIGHT : '';
                }

                if (this.selectedWeightUnit === 'KG') {
                    this.parcel.weight = this.parcel.type.RTN_PAT_METRIC_WEIGHT ? this.parcel.type.RTN_PAT_METRIC_WEIGHT : '';
                } else {
                    this.parcel.weight = this.parcel.type.RTN_PAT_IMPERIAL_WEIGHT ? this.parcel.type.RTN_PAT_IMPERIAL_WEIGHT : '';
                }

                /* Auto-save if no input is required. */
                if (this.parcel.type.RTN_PAT_METRIC_LENGTH && this.parcel.type.RTN_PAT_METRIC_WIDTH && this.parcel.type.RTN_PAT_METRIC_HEIGHT && this.parcel.type.RTN_PAT_METRIC_WEIGHT) {
                    this.saveShipmentUnit();
                }
            },
            getMeasurementUnits() {
                this.isLoadingMeasurementUnits = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/getMeasurementUnits');
                url.searchParams.set('portalId', this.$store.state.portal.attributes.RTN_PTL_ID);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingMeasurementUnits = false;

                    this.parcelMeasurementUnitOptions = data.data.data.measurementUnits;
                    this.parcelWeightUnitOptions = data.data.data.weightUnits;
                });
            },
            getMeasurementSystemByCountryCode() {
                const url = new URL(this.$store.state.portalUrl + 'shipment/getMeasurementSystemByCountryCode');
                url.searchParams.set('countryCode', this.address.countryCode);

                this.$http.get(url.href).then((data) => {
                    if (data.data.data.measurementSystem) {
                        this.setSelectedMeasurementUnit(data.data.data.measurementSystem);
                    }
                });
            },
            getShipmentUnits() {
                this.isLoadingShipmentUnits = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/getShipmentUnits');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingShipmentUnits = false;
                    this.shipmentUnits = {};

                    _.forOwn(data.data.data.shipmentUnits, (shipmentUnit) => {
                        this.shipmentUnits[shipmentUnit.RTN_SPU_ID] = shipmentUnit;
                    });

                    if (Object.keys(this.shipmentUnits).length === 0) {
                        this.showAddParcelRow();
                    }
                });
            },
            saveShipmentUnit() {
                this.errorsShipmentUnit = {};

                if (!this.parcel.type) {
                    this.errorsShipmentUnit.type = this.$store.state.translations.translations['This field is required.'];
                }

                if (!this.parcel.length) {
                    this.errorsShipmentUnit.length = this.$store.state.translations.translations['This field is required.'];
                }

                if (!this.parcel.width) {
                    this.errorsShipmentUnit.width = this.$store.state.translations.translations['This field is required.'];
                }

                if (!this.parcel.height) {
                    this.errorsShipmentUnit.height = this.$store.state.translations.translations['This field is required.'];
                }

                if (!this.parcel.weight) {
                    this.errorsShipmentUnit.weight = this.$store.state.translations.translations['This field is required.'];
                }

                if (Object.keys(this.errorsShipmentUnit).length > 0) {
                    return false;
                }

                this.isSavingShipmentUnit = true;

                const url = new URL(this.$store.state.portalUrl + 'shipment/createShipmentUnit');
                url.searchParams.set('returnId', this.returnId);

                const params = new URLSearchParams();
                params.append('ShipmentUnit[RTN_PAT_ID]', this.parcel.type.RTN_PAT_ID);
                params.append('ShipmentUnit[length]', this.parcel.length);
                params.append('ShipmentUnit[width]', this.parcel.width);
                params.append('ShipmentUnit[height]', this.parcel.height);
                params.append('ShipmentUnit[weight]', this.parcel.weight);
                params.append('ShipmentUnit[RTN_SPU_PARCEL_TYPE]', this.parcel.type.RTN_PAT_TYPE);
                params.append('ShipmentUnit[RTN_SPU_DIMENSION_UNIT]', this.selectedMeasurementUnit);
                params.append('ShipmentUnit[RTN_SPU_WEIGHT_UNIT]', this.selectedWeightUnit);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingShipmentUnit = false;

                    if (data.data.data.errorsShipmentUnit) {
                        this.errorsShipmentUnit = data.data.data.errorsShipmentUnit;
                    } else {
                        this.hideAddParcelRow();

                        this.shipmentUnits[data.data.data.shipmentUnit.RTN_SPU_ID] = data.data.data.shipmentUnit;
                    }
                });
            },
            deleteShipmentUnit(shipmentUnitId) {
                this.shipmentUnitsBeingDeleted.push(shipmentUnitId);

                const url = new URL(this.$store.state.portalUrl + 'shipment/deleteShipmentUnit');
                url.searchParams.set('returnId', this.returnId);
                url.searchParams.set('shipmentUnitId', shipmentUnitId);

                const params = new URLSearchParams();
                params.append('ShipmentUnit[RTN_SPU_ID]', shipmentUnitId);

                this.$http.post(url.href, params).then((data) => {
                    if (data?.data?.data?.errors) {
                        const deleteIndex = this.shipmentUnitsBeingDeleted.indexOf(shipmentUnitId);

                        if (deleteIndex > -1) {
                            this.shipmentUnitsBeingDeleted.splice(deleteIndex, 1);
                        }

                        this.errorsShipmentUnit = data.data.data.errors;
                    } else {
                        delete this.shipmentUnits[shipmentUnitId];

                        if (Object.keys(this.shipmentUnits).length === 0) {
                            this.showAddParcelRow();
                        }
                    }

                    if (Object.keys(this.shipmentUnits).length === 0) {
                        this.hasSelectedSettlementProposal = false;
                        this.unsetSelectedCarrier();
                    }
                });
            },
            setSelectedMeasurementUnit(unit) {
                if (this.hasMetricUnits && this.hasImperialUnits) {
                    if (unit === 'metric') {
                        this.selectedMeasurementUnit = 'CM';
                        this.selectedWeightUnit = 'KG';
                    } else {
                        this.selectedMeasurementUnit = 'IN';
                        this.selectedWeightUnit = 'LB';
                    }
                } else if (this.hasMetricUnits) {
                    this.selectedMeasurementUnit = 'CM';
                    this.selectedWeightUnit = 'KG';
                } else if (this.hasImperialUnits) {
                    this.selectedMeasurementUnit = 'IN';
                    this.selectedWeightUnit = 'LB';
                }
            },
            getPaymentStatus() {
                const url = new URL(this.$store.state.portalUrl + 'payment/hasPaidPayment');
                url.searchParams.append('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    if (data.data.data.result && data.data.data.result === true) {
                        this.$router.push({
                            name: 'PaymentStatus',
                            query: {
                                returnId: this.returnId,
                                orderId: this.orderId,
                                merchantSetupId: this.merchantSetupId
                            }
                        });
                    }
                });
            },
            validatePostalCode() {
                if (this.isAddressFormComplete()) {
                    this.saveAddress();
                    return;
                }

                delete this.errorsAddress['RTN_LNA_POSTAL'];

                const url = new URL(this.$store.state.portalUrl + 'shipment/validatePostalCode');
                const params = new URLSearchParams();

                params.append('Address[POSTAL_CODE]', this.address.postalCode);
                params.append('Address[COUNTRY_CODE]', this.address.countryCode);

                this.$http.post(url.href, params).then((data) => {
                    if (data.data.data.error) {
                        this.errorsAddress = {RTN_LNA_POSTAL: data.data.data.error};
                    }
                });
            },
            isAddressFormComplete() {
                return this.address.addressLine1 && this.address.city && ((this.formConfig.postalCode.label && this.address.postalCode) || !this.formConfig.postalCode.label);
            },
            getCarriers() {
                let getCarriersUrl = new URL(this.appBaseUrl + 'shipment/getAvailableCarriers');
                getCarriersUrl.searchParams.set('returnId', this.returnId);

                this.isLoadingCarriers = true;

                this.$http.get(getCarriersUrl.href).then((data) => {
                    this.carriers = data.data.data.carriers;
                    this.carrierParams = data.data.data.carrierParams;
                    this.predefinedCarriers = data.data.data.predefinedCarriers;
                    this.isLoadingCarriers = false;
                });
            },
            checkCarrier(shipmentUnit, carrier) {
                let result = true;
                const parcelTypeAllowedCarrierIds = this.getParcelTypeAllowedCarrierIds(shipmentUnit.RTN_PAT_ID);

                if (!parcelTypeAllowedCarrierIds || !parcelTypeAllowedCarrierIds.includes(carrier.RTN_CRR_ID)) {
                    result = false;
                }

                if (!this.carrierParams[carrier.RTN_CRR_ID].allowedParcelTypes || !this.carrierParams[carrier.RTN_CRR_ID].allowedParcelTypes.includes(shipmentUnit.RTN_SPU_PARCEL_TYPE)) {
                    result = false;
                }

                if (this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxWeight && parseFloat(shipmentUnit.RTN_SPU_WEIGHT) > parseFloat(this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxWeight)) {
                    result = false;
                }

                if (this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxLength && parseFloat(shipmentUnit.RTN_SPU_PARCEL_LENGTH) > parseFloat(this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxLength)) {
                    result = false;
                }

                if (this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxWidth && parseFloat(shipmentUnit.RTN_SPU_PARCEL_WIDTH) > parseFloat(this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxWidth)) {
                    result = false;
                }

                if (this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxHeight && parseFloat(shipmentUnit.RTN_SPU_PARCEL_HEIGHT) > parseFloat(this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxHeight)) {
                    result = false;
                }

                if (this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxLGirth) {
                    const lgirth = parseFloat(shipmentUnit.RTN_SPU_PARCEL_LENGTH) + ((parseFloat(shipmentUnit.RTN_SPU_PARCEL_WIDTH) * 2) + (parseFloat(shipmentUnit.RTN_SPU_PARCEL_HEIGHT) * 2));

                    if (lgirth > parseFloat(this.carrierParams[carrier.RTN_CRR_ID].parcelMetricMaxLGirth)) {
                        result = false;
                    }
                }
                return result;
            },
            afterSelectingSettlementProposal() {
                this.hasSelectedSettlementProposal = true;
            }
        },
        computed: {
            hasMetricUnits() {
                return this.parcelMeasurementUnitOptions && this.parcelWeightUnitOptions && (this.parcelMeasurementUnitOptions['CM'] || this.parcelWeightUnitOptions['KG']);
            },
            hasImperialUnits() {
                return this.parcelMeasurementUnitOptions && this.parcelWeightUnitOptions && (this.parcelMeasurementUnitOptions['IN'] || this.parcelWeightUnitOptions['LB']);
            },
            filteredCarriers() {
                let preselectedCarriers = this.carriers.filter((carrier) => {
                    if(this.predefinedCarriers.length > 0 && (!this.predefinedCarriers.includes(parseInt(carrier.RTN_CRR_ID)))) {
                        return false;
                    }
                    return true;
                });

                let filterFn = (carrierArr) => {
                    return carrierArr.filter((carrier) => {
                        /* Return false if multiple parcels are selected, but the carrier does not support multiple parcels. */
                        if (Object.keys(this.shipmentUnits).length > 1 && !this.carrierParams[carrier.RTN_CRR_ID].allowMultipleParcels) {
                            if (this.selectedCarrierId === carrier.RTN_CRR_ID) {
                                this.unsetSelectedCarrier();
                            }

                            return false;
                        }

                        let result = true;


                        if(this.allowPackageMerge) {
                            let biggest = {unit: false, girth: 0};
                            _.forOwn(this.shipmentUnits, (shipmentUnit) => {
                                let girth = parseFloat(shipmentUnit.RTN_SPU_PARCEL_LENGTH) + ((parseFloat(shipmentUnit.RTN_SPU_PARCEL_WIDTH) * 2) + (parseFloat(shipmentUnit.RTN_SPU_PARCEL_HEIGHT) * 2));

                                if(biggest.girth == 0 || girth > biggest.girth) {
                                    biggest.girth = girth;
                                    biggest.unit = shipmentUnit;
                                }
                            });

                            if(!this.checkCarrier(biggest.unit, carrier))
                                result = false;
                        } else {
                            _.forOwn(this.shipmentUnits, (shipmentUnit) => {
                                if(!this.checkCarrier(shipmentUnit, carrier))
                                    result = false;
                            });
                        }

                        if (!result) {
                            if (this.selectedCarrierId === carrier.RTN_CRR_ID) {
                                this.unsetSelectedCarrier();
                            }

                            return false;
                        }

                        return true;
                    });
                };


                if(preselectedCarriers.length < 1) {
                    return filterFn(this.carriers);
                }
                else {
                    let filtered = filterFn(preselectedCarriers);
                    return filtered.length < 1 ? filterFn(this.carriers) : filtered;
                }
            },
            organizationLocationsInCountry() {
                let locations = [];

                _.forOwn(this.organizationLocations, (location) => {
                    if (this.address.countryCode && location.Country && location.Country === this.address.countryCode) {
                        locations.push({value: location.AddressId, text: location.LocationName + ' (' + location.AddressLine1 + ')' });
                    }
                });

                return locations;
            },
            selectedCarrierRequiresLabelPayment() {
                return this.carrierParams[this.selectedCarrier.RTN_CRR_ID].labelCost && this.carrierParams[this.selectedCarrier.RTN_CRR_ID].customerCostCurrency && this.carrierParams[this.selectedCarrier.RTN_CRR_ID].labelCost > 0;
            },
            canCreateShipment() {
                return this.selectedCarrier && !this.isSavingAddress && !this.isSavingCarrier && Object.keys(this.shipmentUnits).length > 0;
            },
            showSettlementProposalWarning() {
              return !this.hasSavedAddress;
            },
            showPackageTypeOptions() {
              return this.hasSavedAddress && (
                  (!this.useSettlementProposals && !this.hasSelectedSettlementProposal) ||
                  (this.useSettlementProposals && this.hasSelectedSettlementProposal)
              );
            },
            showPackageTypeWarning() {
              return (
                  (!this.useSettlementProposals && !this.hasSavedAddress) ||
                  (this.useSettlementProposals && !this.hasSelectedSettlementProposal)
              );
            },
            hasPackageTypes() {
              return this.parcelTypes.length !== 0;
            }
        },
        watch: {
            'address.administrativeArea': function() {
                if(this.administrativeAreaIsLoaded) {
                    this.saveAddress();
                }
            },
            organizationLocationsInCountry(locations) {
                if (this.currentUser && this.currentUser.OrganizationId && locations && locations.length === 1) {
                    const location = _.find(this.organizationLocations, ['AddressId', locations[0].value]);
                    this.populateAddressFields(location);
                    this.saveAddress();
                }
            },
            selectedOrganizationLocation(selectedAddressId) {
                _.forOwn(this.organizationLocations, (location) => {
                    if (location.AddressId === selectedAddressId) {
                        this.populateAddressFields(location);
                        this.saveAddress();
                    }
                });
            },
            selectedMeasurementUnit() {
                this.prepopulateParcelTypeData()
            },
            selectedWeightUnit() {
                this.prepopulateParcelTypeData()
            },
            shipmentUnits() {
                this.calculateCarrierOptionHeight();
            },
            filteredCarriers(filteredCarriers) {
                if (filteredCarriers.length === 1 && (!this.useSettlementProposals || this.hasSelectedSettlementProposal)) {
                    this.autoSelectCarrier(filteredCarriers);
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .details {
        height: 0;
        overflow: hidden;
        transition: 0.2s;
    }

    .details-visible {
        opacity: 1;
        height: auto;
    }

    .error-text {
        color: red;
    }

    .btn-primary {
        color: #fff !important;
    }

    .btn--shipping-unit-amount {
        color: #fff;
        border: none;
        font-weight: bold;
        letter-spacing: 0.09px;

        text-transform: uppercase;
        font-size: 15px;
        font-family: "HK Grotesk",sans-serif;
        border-radius: 7px;
        padding: 10px 13px;
        background: var(--color-primary);
        line-height: 1;

        margin: 10px 5px;
    }

    .btn--shipping-unit-amount:hover {
        background: var(--color-primary-darkest);
    }

    .selected {
        font-weight: bold;
    }
</style>
