<template>
    <div>
        <main class="main">
            <p>
                Logging in
            </p>
        </main>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                appBaseUrl: this.$store.state.portalUrl,
            };
        },
        created() {
            const url = new URL(this.appBaseUrl + 'user/sso');
            url.searchParams.set('token', this.$route.query.token);

            this.$http.get(url.href).then((data) => {
                if (data?.data?.data?.redirectUrl) {
                    this.$router.push({'path': data.data.data.redirectUrl});
                }
            });
        },
    }
</script>

<style>

</style>
