<template>
    <div v-if="errorsToDisplay">
        <div class="my-3 text-danger" v-for="(error, key) of errorsToDisplay" :key="key">
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'attribute',
        'errors'
    ],
    methods: {
        addToArray(input, output) {
            if (Array.isArray(input)) {
                for (const i of input) {
                    output.push(i);
                }
            } else {
                output.push(input);
            }
        }
    },
    computed: {
        /**
         * Errors to display supports the following:
         *
         * - Displaying all errors (no attribute prop)
         * - Displaying specific errors, using the attribute prop. This can be a single attribute, or an array of attributes.
         *
         * @returns {Array}
         */
        errorsToDisplay() {
            if (!this.errors) {
                return [];
            }

            let result = [];

            if (this.attribute) {
                if (Array.isArray(this.attribute)) {
                    for (const attr of this.attribute) {
                        this.addToArray(this.errors[attr], result);
                    }
                } else if (this.errors[this.attribute]) {
                    this.addToArray(this.errors[this.attribute], result);
                }
            } else if (typeof this.errors === 'object') {
                for (const error of Object.values(this.errors)) {
                    this.addToArray(error, result);
                }
            } else if (typeof this.errors === 'string') {
                this.addToArray(this.errors, result);
            }

            return result;
        }
    }
}
</script>

<style scoped>

</style>
