import axios from '../classes/Axios';
import _ from 'lodash';

const draftReturnStore = {
    namespaced: true,
    state: {
        returns: null
    },
    mutations: {
        set(state, draftReturns) {
            state.returns = draftReturns;
        },
        add(state, draftReturn) {
            if (_.isEmpty(state.returns)) {
                state.returns = {};
            }

            state.returns[draftReturn.ReturnId] = draftReturn;
        },
        setAttributeValue(state, [returnId, attribute, value]) {
            if (_.isEmpty(state.returns) || !state.returns[returnId]) {
                return false;
            }

            state.returns[returnId][attribute] = value;
        },
        delete(state, draftReturnId) {
            if (!_.isEmpty(state.returns) && !_.isEmpty(state.returns[draftReturnId])) {
                delete state.returns[draftReturnId];
            }
        },
        clear(state) {
            state.returns = null;
        }
    },
    actions: {
        getAll(argObject) {
            return new Promise((resolve) => {
                if (argObject.state.returns) {
                    resolve(argObject.state.returns);
                } else {
                    let url = new URL(this.state.portalUrl + 'return/getByCategory');
                    url.searchParams.set('category', 'draft');
                    axios.get(url.href).then((data) => {
                        let returns = data.data.data.returns;
                        // leeg resultaat
                        if (_.isEmpty(returns)) {
                            returns = {};
                        }
                        argObject.commit('set', returns);
                        resolve(returns);
                    });
                }
            });
        }
    }
};
export default draftReturnStore;
