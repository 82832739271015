<template>
    <div>
        <content-block :section="contentBlockSection" :key="$store.state.translations.currentLanguage" v-if="showContentBlockSection" />

        <ProgressBar :currentModule="progressBarCurrentModule" :currentStep="progressBarCurrentStep" v-if="showBreadcrumbs" />
        <div class="my-5" v-else></div>
    </div>
</template>

<script>
    import ProgressBar from '@/components/_ProgressionBar.vue';
    import ContentBlock from '@/components/_ContentBlock.vue';

    export default {
        props: [
            'contentBlockSection',
            'progressBarCurrentModule',
            'progressBarCurrentStep'
        ],
        components: {
            'ProgressBar': ProgressBar,
            'ContentBlock': ContentBlock,
        },
        computed: {
            showBreadcrumbs() {
                return this.$store.state.portal && this.$store.state.portal.parameters.displayBreadcrumbs;
            },
            showContentBlockSection() {
                const contents = this.$store.state.portalContent?.content?.[this.$store.state.translations?.currentLanguage]?.[this.contentBlockSection];
                const isActive = contents?.RTN_PCT_ACTIVE != 0;
                const hasTitle = !!contents?.RTN_PCT_TITLE;
                const hasSubtitle = !!contents?.RTN_PCT_SUB_TITLE;
                const hasContent = !!contents?.RTN_PCT_CONTENT;

                return isActive && (hasTitle || hasSubtitle || hasContent);
            }
        }
    }
</script>

<style scoped>

</style>
