<template>
    <div>
        <div class="container-fluid overflow-x-hidden">
            <div class="row">
                <div class="col-lg-6 block-left">
                    <nav class="navbar lang-absolute-container">
                        <LanguageSelector/>
                    </nav>

                    <div class="block-left-content">
                        <a href="" class="back-link invisible">
                            &lt; {{ $store.state.translations.translations['Back'] }}
                        </a>

                        <h1>
                            {{ $store.state.translations.translations['Login'] }}
                        </h1>

                        <span class="simple-text">
                            {{ $store.state.translations.translations['Login to your {accountname} returns portal.'].replace('{accountname}', $store.state.portal.attributes.RTN_PTL_NAME) }}
                        </span>

                        <InputFieldError attribute="global" :errors="errors"/>

                        <form action="#" class="form">
                            <div class="form-group">
                                <label for="email">
                                    {{ $store.state.translations.attributeLabels['RTN_RTN_EMAIL'] }}
                                </label>

                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="icon-icon-mail"></span>
                                    </div>

                                    <input id="email"
                                           type="email"
                                           v-model="user.email"
                                           class="form-control"
                                           :disabled="isLoggingIn"
                                    >
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label for="passw">
                                    {{ $store.state.translations.translations['Password'] }}
                                </label>

                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="icon-icon-key"></span>
                                    </div>

                                    <input :type="passwordFieldType"
                                           v-model="user.password"
                                           id="passw"
                                           class="form-control password"
                                           :disabled="isLoggingIn"
                                    >

                                    <div class="input-group-append">
                                        <span class="icon-icon-eye cursor-pointer" @click="togglePasswordShow()"></span>
                                    </div>
                                </div>

                                <router-link class="forgot-passw" :to="{name: 'ForgotPassword'}">
                                    {{ $store.state.translations.translations['forgot password?'] }}
                                </router-link>
                            </div>

                            <div class="form-group">
                                <label class="checkbox-container">
                                    {{ $store.state.translations.translations['Remember me'] }}
                                    <input type="checkbox" checked="checked">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div class="d-flex d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between pt-3 pt-md-4 mb-5">
                                <router-link class="btn btn-secondary md mb-3"
                                             :to="{name: 'Register'}"
                                             v-if="$store.state.portal.parameters.userCreationEnabled"
                                >
                                    {{ $store.state.translations.translations['Create account'] }}
                                </router-link>

                                <button type="button"
                                        name="button"
                                        @click.prevent="login()"
                                        class="btn btn-primary md mb-3"
                                        :disabled="isLoggingIn"
                                >
                                    {{ $store.state.translations.translations['Login'] }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-6 block-right">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LanguageSelector from '@/components/_LanguageSelector.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import _ from 'lodash';

    export default {
        name: 'LoginPage',
        components: {
            'LanguageSelector': LanguageSelector,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                errors: {global: ''},
                user: {
                    email: '',
                    password: '',
                },
                passwordFieldType: 'password',
                isLoggingIn: false,
            }
        },
        created() {

        },
        mounted() {
            this.$windowEventManager.addEventListener('keydown', (event) => {
                if (event.keyCode === 13) {
                    this.login();
                }
            });
        },
        methods: {
            login() {
                this.isLoggingIn = true;

                const url = new URL(this.$store.state.portalUrl + 'user/login');
                const params = new URLSearchParams();
                params.append('user[email]', this.user.email);
                params.append('user[password]', this.user.password);

                this.$http.post(url.href, params).then((data) => {
                    this.isLoggingIn = false;

                    if (data.data.data.errors) {
                        let errors = {};

                        _.forOwn(data.data.data.errors.user, function (value) {
                            errors['global'] = value;
                        });

                        this.errors = errors;
                    } else {
                        this.isLoggingIn = true;
                        this.$store.commit('setCurrentUser', data.data.data.user);
                        this.$router.push({
                            name: 'Index'
                        });
                    }
                });
            },
            togglePasswordShow() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            }
        }
    }
</script>

<style>

</style>
