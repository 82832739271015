<template>
    <div ref="parent">
        <label class="mt-3" v-if="showTitle !== false">
            {{ $store.state.translations.translations['Product'] }}
        </label>

        <template v-if="isLoading">
            <p>
                {{ $store.state.translations.translations['Loading your products...'] }}
            </p>
        </template>

        <template v-else-if="selectedArticleId || selectedMSPDWHId">
            <div class="accordion-head">
                <div class="accordion-head-td second">
                    {{ $store.state.translations.translations['Product'] }}
                </div>

                <div class="accordion-head-td third">
                    {{ $store.state.translations.translations['Description'] }}
                </div>

                <div class="accordion-head-td fourth"></div>
            </div>

            <div id="accordion" class="accordion">
                <div class="accordion-card">
                    <div class="accordion-card-header">
                        <div class="accordion-card-header-td second">
                            <template v-if="params.articleDisplayMethod === 'MSPDWH_searchBar'">
                                {{ searchResultProductList[selectedMSPDWHId].MODEL_NUMBER }}
                            </template>

                            <template v-else-if="params.articleDisplayMethod === 'searchBar'">
                                {{ searchResultProductList[selectedArticleId].ModelNumber }}
                            </template>

                            <template v-else>
                                {{ $store.state.articleModule.articles[selectedArticleId].ModelNumber }}
                            </template>
                        </div>

                        <div class="accordion-card-header-td third">
                            <template v-if="params.articleDisplayMethod === 'MSPDWH_searchBar'">
                                {{ searchResultProductList[selectedMSPDWHId].DESCRIPTION }}
                            </template>

                            <template v-else-if="params.articleDisplayMethod === 'searchBar'">
                                {{ searchResultProductList[selectedArticleId].Description }}
                            </template>

                            <template v-else>
                                {{ $store.state.articleModule.articles[selectedArticleId].Description }}
                            </template>
                        </div>

                        <div class="accordion-card-header-td fourth">
                            <a href="#collapseOne" class="btn-link" data-toggle="collapse" aria-controls="collapseOne" @click.prevent="closeArticleForm()">
                                &times;
                            </a>
                        </div>
                    </div>

                    <div class="collapse-body">
                        <div class="card-body">
                            <ProductDetailsForm :params="params"
                                                :articleGroupId="articleGroupId ? articleGroupId : selectedArticleGroupId"
                                                :errors="errors"
                                                @productDetailsFormLoaded="loadedForm()"
                                                :getDetailsOnLoad="true"
                                                ref="productDetailsForm"
                                                :class="productSaving ? 'd-none' : 'd-block'"
                            />

                            <template v-if="productSaving">
                                <p>
                                    {{ $store.state.translations.translations['Saving product...'] }}
                                </p>
                            </template>

                            <template v-else>
                                <button type="button" class="btn md btn-primary mt-3" v-on:click="saveProduct()" v-if="!isLoadingForm">
                                    {{ $store.state.translations.translations['Add'] }}
                                </button>

                                <InputFieldError attribute="general" :errors="errors" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <!-- Begin search bar view -->
            <template v-if="params.articleDisplayMethod === 'searchBar'">
                <div v-if="productSaving">
                    {{ $store.state.translations.translations['Saving product...'] }}
                </div>

                <model-list-select
                    v-else
                    :list="values(searchResults)"
                    option-value="ArticleId"
                    option-text="DropdownValue"
                    v-model="selectedArticleId"
                    placeholder="Search for an article"
                    @searchchange="searchArticle"
                    id="articleSearchInputField"
                >
                </model-list-select>
            </template>
            <!-- End search bar view -->

            <!-- Begin search bar view -->
            <template v-if="params.articleDisplayMethod === 'MSPDWH_searchBar'">
                <div v-if="productSaving">
                    {{ $store.state.translations.translations['Saving product...'] }}
                </div>

                <v-select
                    :options="values(searchResultsMSPDWH)"
                    label="LABEL"
                    :reduce="e => e.ID"
                    v-model="selectedMSPDWHId"
                    :clear-search-on-blur="() => { return false; }"
                    v-on:search="searchMSPDWH"
                >
                </v-select>
            </template>
            <!-- End search bar view -->

            <!-- Begin dropdown list view -->
            <template v-else-if="params.articleDisplayMethod === 'dropdownMenu'">
                <div v-if="productSaving">
                    {{ $store.state.translations.translations['Saving product...'] }}
                </div>

                <v-select :options="values($store.state.articleModule.articles)" label="ModelNumber" :reduce="article => article.ArticleId" v-model="selectedArticleId" v-else>
                    <template v-slot:option="option">
                        <span>{{ option.ModelNumber }}</span>
                        <span class="option-description" v-if="option.Description">| {{ option.Description }}</span>
                        <span v-if="params.showProductPrices === true && option.ProductPrice">| {{option.CurrencySymbol}} {{ option.ProductPrice }}</span>
                    </template>

                    <template v-slot:selected-option="option">
                        <span>{{ option.ModelNumber }}</span>
                        <span class="option-description" v-if="option.Description">| {{ option.Description }}</span>
                        <span v-if="params.showProductPrices === true && option.ProductPrice">| {{option.CurrencySymbol}} {{ option.ProductPrice }}</span>
                    </template>
                </v-select>
            </template>
            <!-- End dropdown list view -->

            <!-- Begin grid view (DEFAULT) -->
            <template v-else>
                <div class="model-number-list pb-4">
                    <div v-if="productSaving">
                        <div class="model-item-wrapper">
                            {{ $store.state.translations.translations['Saving product...'] }}
                        </div>
                    </div>

                    <div v-else class="model-item-wrapper" v-for="article in $store.state.articleModule.articles" :key="article.ArticleId">
                        <a href="" class="model-item" @click.prevent="selectArticle(article.ArticleId)">
                            <span>{{ article.ModelNumber }}</span>
                            <span v-if="params.showProductPrices === true && article.ProductPrice">{{article.CurrencySymbol}} {{ article.ProductPrice }}</span>
                            <span class="description">{{ article.Description }}</span>
                        </a>
                    </div>
                </div>
            </template>
            <!-- End grid view -->
        </template>
    </div>
</template>

<script>
    import vSelect from 'vue-select';
    import {ModelListSelect} from 'vue-search-select';
    import ProductDetailsForm from './_ProductDetailsForm.vue';
    import _ from 'lodash';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'ModelListSelect': ModelListSelect,
            'v-select': vSelect,
            'ProductDetailsForm': ProductDetailsForm,
            'InputFieldError': InputFieldError,
        },
        props: [
            'params',
            'articleGroupId',
            'showTitle'
        ],
        data() {
            return {
                errors: {},
                productSaving: false,
                returnId: this.$route.query.returnId,
                orderId: this.$route.query.orderId,
                merchantSetupId: this.$route.query.merchantSetupId,
                isLoading: false,
                isLoadingForm: true,
                searchResults: [],
                searchResultsMSPDWH: [],
                searchResultProductList: [],
                selectedArticleId: null,
                selectedMSPDWHId: null,
                selectedArticleGroupId: null,
                additionalInfoLoaded: false,
                productToAdd: null,
                customAttributes: null,
                saveUrl: new URL(this.$store.state.portalUrl + 'product/save'),
                form: {},
                requestedQuantity: 1,
                purchaseDate: null,
                warrantyDate: null,
                returnReasons: {},
                serviceRequests: {},
                selectedReturnReason: null,
                selectedServiceRequest: null,
                timer: null,
            }
        },
        created() {
            if (this.params.articleDisplayMethod === 'searchBar') {
                this.$nextTick(() => {
                    document.getElementById('articleSearchInputField').focus();
                });
            } else if (this.params.articleDisplayMethod === 'MSPDWH_searchBar') {
                this.$nextTick(() => {
                    //document.getElementById('articleSearchMSPDWHInputField').focus();
                });
            } else {
                this.isLoading = true;

                if (this.articleGroupId) {
                    this.$store.dispatch('articleModule/getFromGroup', [this.returnId, this.articleGroupId]).then(() => {
                        this.isLoading = false;
                    });
                } else {
                    this.$store.dispatch('articleModule/getAll', this.merchantSetupId).then(() => {
                        this.isLoading = false;
                    });
                }
            }
        },
        methods: {
            selectArticle(articleId) {
                this.selectedArticleId = articleId;

                if (this.params.articleDisplayMethod === 'searchBar' && this.searchResults[articleId]) {
                    this.selectedArticleGroupId = this.searchResults[articleId].ArticleGroupId;
                } else if (this.params.articleDisplayMethod !== 'searchBar' && this.$store.state.articleModule.articles[articleId]) {
                    this.selectedArticleGroupId = this.$store.state.articleModule.articles[articleId].ArticleGroupId;
                }
            },
            selectMSPDWHId(id) {
                this.selectedMSPDWHId = id;
            },
            saveProduct() {
                this.errors = {};

                let data = this.$refs.productDetailsForm.getData();
                let formData = new FormData();

                this.productSaving = true;
                let url = new URL(this.$store.state.portalUrl + 'product/save');
                url.searchParams.set('orderId', this.orderId);
                if (this.selectedArticleId)
                    url.searchParams.set('articleId', this.selectedArticleId);
                else {
                    url.searchParams.set('selectedID', this.selectedMSPDWHId);
                    let item = this.searchResultProductList[this.selectedMSPDWHId];
                    for (let i in item) {
                        if (item[i])
                            formData.append('ProductPDWH[' + i + ']', item[i]);
                    }
                }

                formData.append('Product[CUSTOM_ATTRIBUTE_OPTION]', 'SPP_PRODUCT_FEED');

                for (const [key, value] of Object.entries(data.attributes)) {
                    formData.append('Product[' + key + ']', value !== null ? value : '');
                }

                for (const [key, value] of Object.entries(data.customAttributes)) {
                    if (value !== null) {
                        formData.append('Product[' + key + ']', value);
                    }
                }

                this.$http.post(url.href, formData).then((data) => {
                    this.productSaving = false;

                    if (data.data.data.errors) {
                        this.errors = data.data.data.errors;
                    } else {
                        let product = data.data.data.product;
                        this.additionalInfoLoaded = false;
                        this.selectedArticleId = null;
                        this.selectedMSPDWHId = null;
                        this.$store.commit('products/add', {orderId: this.orderId, product: product});

                        this.$nextTick(() => {
                            if (document.getElementById('articleSearchInputField')) {
                                document.getElementById('articleSearchInputField').focus();
                            } else if (document.getElementById('articleSearchMSPDWHInputField')) {
                                document.getElementById('articleSearchMSPDWHInputField').focus();
                            }
                        });
                    }
                });
            },
            searchArticle(input) {
                if (input.length >= 3) {
                    this.$store.dispatch('articleModule/search', [input, this.returnId, this.merchantSetupId, this.articleGroupId]).then(() => {
                        if (this.articleGroupId) {
                            this.searchResults = this.$store.state.articleModule.searchedArticles[this.articleGroupId][input];
                        } else {
                            this.searchResults = this.$store.state.articleModule.searchedArticles[input];
                        }
                    });
                } else {
                    this.searchResults = [];
                }
            },
            searchMSPDWH(input) {
                if (input.length >= 5) {
                    clearTimeout(this.timer);

                    this.timer = setTimeout(() => {
                        let url = new URL(this.$store.state.portalUrl + 'product/searchMSPDWH');
                        url.searchParams.set('term', input);

                        this.$http.get(url.href).then((data) => {
                            this.searchResultsMSPDWH = data.data.data.products;
                        });
                    }, 750);
                }
            },
            getAdditionalInfo() {
                let url = new URL(this.$store.state.portalUrl + 'product/getAdditionalInfo');
                url.searchParams.set('returnId', this.returnId);

                this.$http.get(url.href).then((data) => {
                    this.additionalInfoLoaded = true;
                    this.customAttributes = data.data.data.customAttributes;
                    this.returnReasons = data.data.data.returnReasons;
                    this.serviceRequests = data.data.data.serviceRequests;
                });
            },
            loadedForm() {
                this.isLoadingForm = false;
            },
            closeArticleForm() {
                this.selectedArticleId = null;
                this.selectedMSPDWHId = null;

                this.$nextTick(() => {
                    if (document.getElementById('articleSearchInputField')) {
                        document.getElementById('articleSearchInputField').focus();
                    }
                });
            },
            values(input) {
                return _.values(input);
            }
        },
        watch: {
            selectedArticleId(articleId) {

                if ((this.params.articleDisplayMethod !== 'searchBar' && this.params.articleDisplayMethod !== 'MSPDWH_searchBar' && this.params.articleDisplayMethod !== 'dropdownMenu') || !articleId) {
                    return;
                }
                this.selectArticle(articleId);
                this.searchResultProductList = this.searchResults;
                this.searchResults = [];
            },
            selectedMSPDWHId(id) {
                if (this.params.articleDisplayMethod !== 'MSPDWH_searchBar' || !id) {
                    return;
                }
                this.selectMSPDWHId(id);
                this.searchResultProductList = this.searchResultsMSPDWH;
                this.searchResultsMSPDWH = [];
            }
        },
    }
</script>

<style scoped>
    .description, .option-description {
        color: rgba(14, 27, 66, 0.44);
    }

    .description {
        padding: 20px 5px 0;
        text-align: center;
    }
</style>
