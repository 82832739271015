import _ from 'lodash';

const contentStore = {
    namespaced: true,
    state: {
        content: null,
    },
    mutations: {
        set(state, [content, language]) {
            if (_.isEmpty(state.content)) {
                state.content = {};
            }

            if (_.isEmpty(state.content[language])) {
                state.content[language] = content;
            }
        }
    },
    actions: {},
};

export default contentStore;
