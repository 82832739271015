var exportObj = {};

class ColorGenerator {

    /**
     * Takes a hex color code and increases or decreases the brightness by the given amount.
     *
     * @param color
     * @param amount
     * @returns string
     */
    lightenDarkenColor(color, amount) {
        let usePound = false;

        if (color[0] === "#") {
            color = color.slice(1);
            usePound = true;
        }

        const num = parseInt(color, 16);

        let r = (num >> 16) + amount;
        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let g = ((num >> 8) & 0x00FF) + amount;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        let b = (num & 0x0000FF) + amount;
        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        return (usePound ? '#' : '') + this.intToHex(r) + this.intToHex(g) + this.intToHex(b);
    }

    /**
     * Takes a hex color code and converts it to an rgba color code.
     *
     * @param color
     * @param alpha
     * @returns string
     */
    opacityColor(color, alpha) {
        if (color[0] === "#") {
            color = color.slice(1);
        }

        const num = parseInt(color, 16);

        let r = num >> 16;
        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        let g = (num >> 8) & 0x00FF;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        let b = num & 0x0000FF;
        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        return 'rgba(' + r + ',' + g + ',' + b + ',0.' + alpha + ')';
    }

    /**
     * Converts int to hex, e.g. 255 becomes FF.
     *
     * @param int
     * @returns string
     */
    intToHex(int) {
        let hex = Number(int).toString(16);

        if (hex.length < 2) {
            hex = '0' + hex;
        }

        return hex;
    }

}

exportObj.instance = new ColorGenerator();

export default exportObj;
