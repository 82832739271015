import axios from 'axios';
// import router from '../router';
// import store from '../store';

axios.defaults.withCredentials = true;


/*
 * Middleware to log the user out / redirect (in the frontend) when the session is expired
*/
axios.interceptors.response.use((config) => {
    return config;
}, (error) => {
    // store.commit('userModule/logout');
    // router.push({
    //     path: '/login'
    // });
    return error;
});

export default axios;
