<template>
    <div class="row">
        <div class="col-6 col-sm-3">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_RTN_REFERENCE'] }}
            </strong>

            <p class="mb-0">
                {{ returnModel.ReturnReference }}
            </p>
        </div>

        <div class="col-6 col-sm-3">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_MERCHANT_SETUP_ID'] }}
            </strong>

            <p class="mb-0">
                {{ returnModel.MerchantSetupLabel }}
            </p>
        </div>

        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_RTN_CREATION'] }}
            </strong>

            <p class="mb-0">
                {{ returnModel.CreatedAt.substring(0, 10) }}
            </p>
        </div>

        <div class="col-6 col-sm-3 mt-3 mt-sm-0">
            <strong>
                {{ $store.state.translations.attributeLabels['RTN_STATUS'] }}
            </strong>

            <p class="mb-0 text-success">
                {{ returnModel.Status }}
            </p>
        </div>

        <div class="col-12" v-if="showEstimatedRefundValue">
            <table class="w-100 mt-5" v-if="orders">
                <thead>
                    <tr>
                        <td>
                            {{ $store.state.translations.translations['Returned product'] }}
                        </td>

                        <td class="text-end">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_APPROVED'] }}
                        </td>

                        <td class="text-end">
                            {{ $store.state.translations.translations['Product price'] }}
                        </td>
                    </tr>
                </thead>

                <tbody v-for="order in orders" :key="order.OrderId">
                    <tr v-for="product in order.products" :key="product.ProductId">
                        <td>
                            {{ product.ModelNumber }}
                        </td>

                        <td class="text-end">
                            {{ product.QuantityApproved }}
                        </td>

                        <td class="text-end">
                            <template v-if="product.ApprovedValue">
                                {{ currencySymbol }}
                                {{ product.ApprovedValue }}
                            </template>

                            <template v-else>
                                -
                            </template>
                        </td>
                    </tr>
                </tbody>

                <tbody v-if="returnModel?.LogisticsReturnValue">
                    <tr>
                        <td></td>
                        <td></td>

                        <td class="text-end">
                            <strong>
                                {{ currencySymbol }}
                                {{ returnModel?.LogisticsReturnValue }}
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="w-100 my-3" v-if="returnModel?.LogisticsOrderShippingCosts">
                <thead>
                    <tr>
                        <td>
                            {{ $store.state.translations.translations['Additional reimbursements'] }}
                        </td>

                        <td></td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            {{ $store.state.translations.translations['Original shipping costs'] }}
                        </td>

                        <td class="text-end">
                            {{ currencySymbol }}
                            {{ returnModel?.LogisticsOrderShippingCosts }}
                        </td>
                    </tr>
                </tbody>
            </table>


            <table class="w-100 my-3" v-if="settlementProposal">
                <thead>
                    <tr>
                        <td>
                            {{ $store.state.translations.attributeLabels['RTN_RSP_SELECTED_SETTLEMENT_PROPOSAL_JSON'] }}

                        </td>
                        <td></td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            {{ settlementProposal?.settlementProposalTranslated?.RTN_SMP_LABEL }}
                        </td>

                        <td class="text-end">

                        </td>
                    </tr>

                    <tr v-if="returnModel?.LogisticsCustomerCosts">
                        <td>
                            {{ $store.state.translations.translations['Additional costs'] }}
                        </td>

                        <td class="text-end">
                            -

                            {{ currencySymbol }}
                            {{ returnModel?.LogisticsCustomerCosts }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="w-100 my-3" v-else>
                <thead>
                    <tr>
                        <td>
                            {{ $store.state.translations.attributeLabels['Service request'] }}
                        </td>

                        <td></td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            {{ serviceRequestLabel }}
                        </td>

                        <td class="text-end">

                        </td>
                    </tr>

                    <tr v-if="returnModel?.LogisticsCustomerCosts">
                        <td>
                            {{ $store.state.translations.translations['Additional costs'] }}
                        </td>

                        <td class="text-end">
                            -

                            {{ currencySymbol }}
                            {{ returnModel?.LogisticsCustomerCosts }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <template v-if="returnModel?.LogisticsSettlementValue">
                <div class="d-flex justify-content-between">
                    <strong>
                        {{ $store.state.translations.translations['Estimated refund value'] }}
                    </strong>

                    <strong>
                        {{ currencySymbol }}
                        {{ returnModel?.LogisticsSettlementValue }}
                    </strong>
                </div>
            </template>

            <template v-else>
                {{ $store.state.translations.translations['We are unable to give you an estimation of the refund value of your return.'] }}
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'returnModel',
            'currencySymbol',
            'serviceRequestType',
            'serviceRequestLabel',
            'settlementProposal'
        ],
        data() {
            return {

            }
        },
        methods: {

        },
        computed: {
            orders() {
                return this.returnModel?.orders;
            },
            showEstimatedRefundValue() {
                /* Only show the estimated refund value if the settlement type is either "refund", "store credit" or "exchange". */
                return ['refund', 'store credit', 'exchange'].includes(this.serviceRequestType);
            }
        }
    }
</script>

<style scoped>
    table thead td {
        font-weight: 700;
    }
</style>
