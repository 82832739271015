import axios from '../classes/Axios';
import _ from 'lodash';

const trackReturnStore = {
    namespaced: true,
    state: {
        returns: null
    },
    mutations: {
        set(state, draftReturns) {
            state.returns = draftReturns;
        },
        add(state, draftReturn) {
            if (_.isEmpty(state.returns)) {
                state.returns = {};
            }

            state.returns[draftReturn.ReturnId] = draftReturn;
        },
        delete(state, draftReturnId) {
            delete state.returns[draftReturnId];
        },
        clear(state) {
            state.returns = null;
        }
    },
    actions: {
        getAll(argObject) {
            return new Promise((resolve) => {
                if (argObject.state.returns) {
                    resolve(argObject.state.returns);
                } else {
                    let url = new URL(this.state.portalUrl + 'return/getByCategory');
                    url.searchParams.set('category', 'track');
                    axios.get(url.href).then((data) => {
                        let returns = data.data.data.returns;
                        // leeg resultaat
                        if (_.isEmpty(returns)) {
                            returns = {};
                        }
                        argObject.commit('set', returns);
                        resolve(returns);
                    });
                }
            });
        },
        getById(argObject, [returnId, excludeArchived = true]) {
            return new Promise((resolve) => {
                if (argObject.state.returns && argObject.state.returns[returnId]) {
                    resolve(argObject.state.returns[returnId]);
                } else {
                    let url = new URL(this.state.portalUrl + 'return/getById');
                    url.searchParams.set('returnId', returnId);
                    url.searchParams.set('excludeArchived', excludeArchived);

                    axios.get(url.href).then((data) => {
                        let returnModel = data.data.data.returnModel;

                        if (_.isEmpty(returnModel)) {
                            returnModel = {};
                        }

                        argObject.commit('add', returnModel);
                        resolve(returnModel);
                    });
                }
            });
        },
    }
};
export default trackReturnStore;
