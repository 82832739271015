import Vue from 'vue';
import axios from '../classes/Axios';
import _ from 'lodash';

const articleStore = {
    namespaced: true,
    state: {
        searchedArticles: [],
        articles: null,
        articleGroupId: null,
    },
    mutations: {
        set(state, articles) {
            state.articles = articles;
        },
        add(state, article) {
            if (_.isEmpty(state.articles)) {
                state.articles = {};
            }

            Vue.set(state.articles, article.ArticleId, article);
        },
        delete(state, articleId) {
            Vue.delete(state.articles, articleId);
        },
        clear(state) {
            state.articles = null;
        },
        setGroup(state, articleGroupId) {
            state.articleGroupId = articleGroupId;
        },
        setSearchResult(state, [searchInput, result, articleGroupId = null]) {
            if (articleGroupId) {
                if (!state.searchedArticles[articleGroupId]) {
                    state.searchedArticles[articleGroupId] = [];
                }

                state.searchedArticles[articleGroupId][searchInput] = result;
            } else {
                state.searchedArticles[searchInput] = result;
            }
        }
    },
    actions: {
        getAll(argObject, merchantSetupId) {
            return new Promise((resolve) => {
                if (argObject.state.articles) {
                    resolve(argObject.state.articles);
                } else {
                    let url = new URL(this.state.portalUrl + 'article/getAll');
                    url.searchParams.set('merchantSetupId', merchantSetupId);

                    axios.get(url.href).then((data) => {
                        let articles = data.data.data.articles;

                        if (_.isEmpty(articles)) {
                            articles = {};
                        }

                        argObject.commit('set', articles);
                        resolve(articles);
                    });
                }
            });
        },
        getFromGroup(argObject, [returnId, articleGroupId]) {
            return new Promise((resolve) => {
                if (argObject.state.articles && argObject.state.articleGroupId === articleGroupId) {
                    resolve(argObject.state.articles);
                } else {
                    let url = new URL(this.state.portalUrl + 'article/getFromGroup');
                    url.searchParams.set('articleGroupId', articleGroupId);
                    url.searchParams.set('returnId', returnId);

                    axios.get(url.href).then((data) => {
                        let articles = data.data.data.articles;

                        if (_.isEmpty(articles)) {
                            articles = {};
                        }

                        argObject.commit('set', articles);
                        argObject.commit('setGroup', articleGroupId);
                        resolve(articles);
                    });
                }
            });
        },
        search(argObject, [searchInput, returnId, merchantSetupId, articleGroupId = null]) {
            return new Promise((resolve) => {
                if (argObject.state.searchedArticles && (argObject.state.searchedArticles[searchInput] || (argObject.state.searchedArticles[articleGroupId] && argObject.state.searchedArticles[articleGroupId][searchInput]))) {
                    resolve(argObject.state.searchedArticles);
                } else {
                    let url = new URL(this.state.portalUrl + 'article/search');
                    url.searchParams.set('term', searchInput);
                    url.searchParams.set('merchantSetupId', merchantSetupId);
                    url.searchParams.set('returnId', returnId);

                    if (articleGroupId) {
                        url.searchParams.set('articleGroupId', articleGroupId);
                    }

                    axios.get(url.href).then((data) => {
                        let articles = data.data.data.articles;

                        if (_.isEmpty(articles)) {
                            articles = {};
                        }

                        argObject.commit('setSearchResult', [searchInput, articles, articleGroupId]);
                        resolve(articles);
                    });
                }
            });
        },
    }
};

export default articleStore;
