<template>
    <div>
        <template v-if="hasDismissedRefund">
            {{ $store.state.translations.translations['You\'ve closed the refund process without finishing it.'] }}

            <span class="btn-link cursor-pointer" @click.prevent="restartRefundProcess()">
                {{ $store.state.translations.translations['Please click here to restart your refund process.'] }}
            </span>
        </template>

        <template v-else-if="status === 'incomplete' && !undefinedStatus">
            <div id="reveni" class="position-relative vh-100"></div>
        </template>

        <template v-else-if="status === 'denied' && !undefinedStatus">
            {{ $store.state.translations.translations['Please note: We apologise for any inconvenience caused, but we want to inform you that your refund request has not been processed successfully. Our team will thoroughly review your request. We kindly request that you continue with the task at hand and kindly send the return back to us.'] }}
        </template>

        <template v-else>
            <div class="row">
                <div class="col-12 mb-4">
                    <strong>
                        {{ $store.state.translations.translations['Instant refund service'] }}
                    </strong>

                    <p>
                        {{ serviceRequest }}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <strong>
                        {{ $store.state.translations.translations['Your refund option'] }}
                    </strong>

                    <p>
                        {{ method }}
                    </p>
                </div>

                <div class="col-12 col-md-6">
                    <strong>
                        {{ $store.state.translations.translations['Your refund amount'] }}
                    </strong>

                    <p>
                        {{ currencySymbol }}
                        {{ refundAmount }}
                    </p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import reveni from 'reveni-js-sdk';

    export default {
        props: [
            'refundData'
        ],
        data() {
            return {
                hasDismissedRefund: false,
                undefinedStatus: false,
            }
        },
        mounted() {
            this.reveniInit();
        },
        methods: {
            reveniInit() {
                if (!reveni || !this.refundData || !this.refundData.orderId || !this.refundData.returnId || this.refundData.status !== 'incomplete') {
                    return;
                }

                reveni.init(
                    this.refundData.orderId,
                    this.refundData.returnId,
                    this.refundData.token,
                    '#reveni',
                    this.refundData.isSandbox,
                    {
                        onFinish: (status) => {
                            if (status === 'dismiss') {
                                this.hasDismissedRefund = true;
                                this.$emit('instant-refund-dismiss');
                            } else if (typeof status === 'undefined') {
                                this.undefinedStatus = true;
                                this.$emit('instant-refund-status-undefined');
                            } else {
                                this.$emit('instant-refund-finish');
                            }
                        }
                    }
                );
            },
            restartRefundProcess() {
                this.$emit('instant-refund-finish');
            }
        },
        computed: {
            method() {
                if (!this.refundData) {
                    return null;
                }

                if (this.refundData.refundMethod === 'bank_transfer') {
                    return this.$store.state.translations.translations['Bank transfer'];
                }

                if (this.refundData.refundMethod === 'store_credit') {
                    return this.$store.state.translations.translations['Store credit'];
                }

                return this.refundData.refundMethod;
            },
            status() {
                if (this.refundData && this.refundData.status) {
                    return this.refundData.status;
                }

                return null;
            },
            serviceRequest() {
                if (this.refundData && this.refundData.serviceRequest) {
                    return this.refundData.serviceRequest;
                }

                return null;
            },
            currencySymbol() {
                if (this.refundData && this.refundData.currencySymbol) {
                    return this.refundData.currencySymbol;
                }

                return null;
            },
            refundAmount() {
                if (this.refundData && this.refundData.refundAmount) {
                    return this.refundData.refundAmount;
                }

                return null;
            }
        }
    }
</script>

<style scoped>

</style>
