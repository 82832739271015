import axios from '../classes/Axios';
import _ from 'lodash';

const shipReturnStore = {
    namespaced: true,
    state: {
        returns: null
    },
    mutations: {
        set(state, shipReturns) {
            state.returns = shipReturns;
        },
        add(state, shipReturn) {
            if (_.isEmpty(state.returns)) {
                state.returns = {};
            }

            state.returns[shipReturn.ReturnId] = shipReturn;
        },
        delete(state, shipReturnId) {
            if (!_.isEmpty(state.returns) && state.returns[shipReturnId]) {
                delete state.returns[shipReturnId];
            }
        },
        clear(state) {
            state.returns = null;
        }
    },
    actions: {
        getAll(argObject) {
            return new Promise((resolve) => {
                if (argObject.state.returns) {
                    resolve(argObject.state.returns);
                } else {
                    let url = new URL(this.state.portalUrl + 'return/getByCategory');
                    url.searchParams.set('category', 'ship');
                    axios.get(url.href).then((data) => {
                        let returns = data.data.data.returns;
                        // leeg resultaat
                        if (_.isEmpty(returns)) {
                            returns = {};
                        }
                        argObject.commit('set', returns);

                        resolve(returns);
                    });
                }
            });
        }
    }
};
export default shipReturnStore;
