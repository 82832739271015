<template>
    <div class="page">
        <SspHeader/>

        <main class="main">
            <section class="middle-section">
                <div class="container text-center">
                    <template v-if="errors">
                        <h1>
                            {{ errors }}
                        </h1>

                        <button @click.prevent="previous()" class="btn btn-primary md mt-5">
                            {{ $store.state.translations.translations['Request a new return'] }}
                        </button>
                    </template>

                    <template v-else>
                        <h1>
                            {{ $store.state.translations.translations['Fetching page...'] }}
                        </h1>
                    </template>
                </div>
            </section>
        </main>

        <SspFooter />
    </div>
</template>

<script>
    import mcpnext from '@/mixins/mcpnext.js';
    import languageSelect from '@/mixins/languageSelect.js';
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';

    export default {
        mixins: [
            mcpnext,
            languageSelect
        ],
        components: {
            SspHeader,
            SspFooter,
        },
        data() {
            return {
                errors: null,
                isCreatingReturn: false,
            };
        },
        created() {
            if (this.availableLanguages) {
                this.createReturnWithBrowserLanguage();
            }
        },
        methods: {
            createReturnWithBrowserLanguage() {
                if (this.isCreatingReturn) {
                    return false;
                }

                if (this.availableLanguages && navigator.language) {
                    const browserLanguage = navigator.language.slice(0, 2);

                    if (browserLanguage !== this.$store.state.translations.currentLanguage && Object.hasOwn(this.availableLanguages, browserLanguage)) {
                        this.selectLanguage(browserLanguage);
                        global.context.authorization.$on('switchedLanguage', () => {
                            this.createReturn();
                        });
                    } else if (this.$store.state.translations.currentLanguage !== 'en' && !Object.hasOwn(this.availableLanguages, browserLanguage)) {
                        this.selectLanguage('en');
                        global.context.authorization.$on('switchedLanguage', () => {
                            this.createReturn();
                        });
                    } else {
                        this.createReturn();
                    }
                }
            },
            createReturn() {
                if (this.isCreatingReturn) {
                    return false;
                }

                this.errors = null;
                this.isCreatingReturn = true;

                const url = new URL(this.$store.state.portalUrl + 'createReturnDeepLink/entry');
                url.searchParams.set('redirect', this.$route.query.redirect);
                url.searchParams.set('id', this.$route.query.id);
                url.searchParams.set('portalId', this.$route.query.portalId);
                url.searchParams.set('merchantSetupId', this.$route.query.merchantSetupId);
                url.searchParams.set('country', this.$route.query.country);

                if (this.$route.query.order) {
                    url.searchParams.set('order', this.$route.query.order);
                }

                if (this.$route.query.orderValidation) {
                    url.searchParams.set('orderValidation', this.$route.query.orderValidation);
                }

                if (this.$route.query.currency) {
                    url.searchParams.set('currency', this.$route.query.currency);
                }

                this.$http.get(url.href).then((data) => {
                    if (data && data.data && data.data.data && data.data.data.errors) {
                        this.errors = data.data.data.errors;
                        return false;
                    }

                    /* Clear the draftReturns store to prevent outdated data. */
                    this.$store.commit('draftReturns/clear');

                    const redirect = data.data.data.redirect;

                    if (redirect === 'orderPage') {
                        this.$router.push({
                            name: data.data.data.merchantSetupHasConnector ? 'OrderConnectorPage' : 'OrderPage',
                            query: {
                                returnId: data.data.data.returnModel.ReturnId,
                                merchantSetupId: data.data.data.merchantSetupId,
                                selectCurrency: data.data.data.selectCurrency
                            }
                        });
                    } else if (redirect === 'productPage') {
                        this.$router.push({
                            name: data.data.data.merchantSetupHasConnector ? 'OrderConnectorProductPage' : 'ProductPage',
                            query: {
                                returnId: data.data.data.returnModel.ReturnId,
                                orderId: data.data.data.orderModel.OrderId,
                                merchantSetupId: data.data.data.merchantSetupId,
                            }
                        });
                    }
                });
            },
            previous() {
                this.$router.push({
                    name: 'MerchantSetupPage'
                });
            }
        },
        computed: {
            availableLanguages() {
                return this.$store.state.header.languages;
            }
        },
        watch: {
            availableLanguages() {
                this.createReturnWithBrowserLanguage();
            }
        }
    }
</script>

<style>

</style>
