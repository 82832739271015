var exportObj = {};

class WindowEventManager {
    constructor() {
        this._eventList = {};
    }

    addEventListener(event, listener, options) {
        if (typeof this._eventList[event] === 'undefined')
            this._eventList[event] = [];

        this._eventList[event].push(listener);
        window.addEventListener(event, listener, options);
    }

    removeEventListener(event) {
        if (typeof this._eventList[event] !== 'undefined') {
            for (let it in this._eventList[event]) {
                window.removeEventListener(event, this._eventList[event][it]);
                delete this._eventList[event][it];
            }
        }
    }
}

exportObj.instance = new WindowEventManager()

exportObj.plugin = {
    install(app) {
        app.config.globalProperties.$windowEventManager = exportObj.instance;
    }
};

export default exportObj;
