import axios from '../classes/Axios';
import _ from 'lodash';

const OCProductModule = {
    namespaced: true,
    state: {
        OCProducts: {},
        loaded: false
    },
    mutations: {
        set(state, args) {
            if(_.isEmpty(state.OCProducts[args.orderId])) {
                state.OCProducts[args.orderId] = {};
            }

            state.OCProducts[args.orderId] = args.OCProducts;
        },
        setLoaded(state, bool) {
            state.loaded = bool;
        },
        add(state, setObj) {
            if (_.isEmpty(state.OCProducts[setObj.orderId])) {
                state.OCProducts[setObj.orderId] = {};
            }

            state.OCProducts[setObj.orderId][setObj.product.index] = setObj.product;
        },
        delete(state, setObj) {
            delete state.OCProducts[setObj.orderId][setObj.index];
        },
        clear(state) {
            state.OCProducts = {};
        }
    },
    actions: {
        getAll(storeArgObj, customArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.OCProducts && storeArgObj.state.OCProducts[customArgObj.orderId]) {
                    let returnObj = {
                        OCProducts: storeArgObj.state.OCProducts[customArgObj.orderId]
                    };
                    resolve(returnObj);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'product/getAllOC');
                    url.searchParams.set('returnId', customArgObj.returnId);
                    url.searchParams.set('orderId', customArgObj.orderId);
                    axios.get(url.href).then((data) => {
                        let OCProducts = data.data.data.OCProducts;
                        // leeg resultaat
                        if (_.isEmpty(OCProducts)) {
                            OCProducts = {};
                        }

                        let commitArgObj = {
                            OCProducts: OCProducts,
                            orderId: customArgObj.orderId
                        };
                        storeArgObj.commit('set', commitArgObj);
                        storeArgObj.commit('setLoaded', true);
                        resolve(commitArgObj);
                    });
                }
            });
        }
    }
};
export default OCProductModule;
