import axios from '../classes/Axios';
import _ from 'lodash';

const orderStore = {
    namespaced: true,
    state: {
        loaded: false,
        orders: {},
    },
    mutations: {
        set(state, setObj) {
            if (_.isEmpty(state.orders[setObj.returnId])) {
                state.orders[setObj.returnId] = {};
            }

            state.orders[setObj.returnId] = setObj.orders;
        },
        add(state, setObj) {
            if (_.isEmpty(state.orders[setObj.returnId])) {
                state.orders[setObj.returnId] = {};
            }

            state.orders[setObj.returnId][setObj.order.OrderId] = setObj.order;
        },
        delete(state, setObj) {
            delete state.orders[setObj.returnId][setObj.OrderId];
        },
        setLoaded(state, bool) {
            state.loaded = bool;
        },
        clear(state) {
            state.orders = {};
            state.loaded = false;
        }
    },
    actions: {
        getAll(storeArgObj, returnId) {
            return new Promise((resolve) => {
                if (storeArgObj.state.orders[returnId]) {
                    resolve(storeArgObj.state.orders[returnId]);
                } else {
                    let url = new URL(storeArgObj.rootState.portalUrl + 'order/getAll');
                    url.searchParams.set('returnId', returnId);
                    axios.get(url.href).then((data) => {
                        let orders = data.data.data.orderModels;
                        // leeg resultaat
                        if (_.isEmpty(orders)) {
                            orders = {};
                        }

                        let setObj = {
                            returnId: returnId,
                            orders: orders
                        };
                        storeArgObj.commit('set', setObj);
                        storeArgObj.commit('setLoaded', true);
                        resolve(orders);
                    });
                }
            });
        },
    }
};
export default orderStore;
