<template>
    <div v-if="displayReturnDetails">
        <div class="container sm">
            <a v-b-toggle="'show-details-dropdown'" class="back-link" href="#" @click.prevent="showDetails()">
                {{ $store.state.translations.translations['Return details'] }}
            </a>
        </div>

        <div class="container sm">
            <b-collapse id="show-details-dropdown" class="container container-full-width-md">
                <div class="custom-table">
                    <div class="custom-table-head">
                        <template v-if="showModelNumber !== false">
                            <div class="custom-table-head-cell one">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_MODEL_NUMBER'] }}
                            </div>

                            <div class="custom-table-head-cell two">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_DESCRIPTION'] }}
                            </div>
                        </template>

                        <template v-else>
                            <div class="custom-table-head-cell half pl-4">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_DESCRIPTION'] }}
                            </div>
                        </template>

                        <div class="custom-table-head-cell small">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_STATUS'] }}
                        </div>

                        <div class="custom-table-head-cell small">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_REQUESTED'] }}
                        </div>

                        <div class="custom-table-head-cell small">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_REJECTED'] }}
                        </div>

                        <div class="custom-table-head-cell small">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_APPROVED'] }}
                        </div>

                        <div class="custom-table-head-cell small">
                            {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_EXPECTED'] }}
                        </div>
                    </div>

                    <div class="custom-table-body" v-for="requestedProduct in requestedProducts" :key="requestedProduct.ProductId">
                        <div class="custom-table-body-row">
                            <template v-if="showModelNumber !== false">
                                <div class="custom-table-td one">
                                    {{ requestedProduct.ModelNumber }}
                                </div>

                                <div class="custom-table-td two">
                                    {{ requestedProduct.Description }}
                                </div>
                            </template>

                            <template v-else>
                                <div class="custom-table-td half pl-4">
                                    {{ requestedProduct.Description }}
                                </div>
                            </template>

                            <div class="custom-table-th-mobile">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_STATUS'] }}
                            </div>

                            <div class="custom-table-th-mobile">

                            </div>

                            <div class="custom-table-td small">
                                <span v-if="requestedProduct.Status === 'approved'" class="u-colored-success status-text">
                                    {{ requestedProduct.Status }}
                                </span>

                                <span v-else-if="requestedProduct.Status === 'rejected'" class="u-colored-danger status-text">
                                    {{ requestedProduct.Status }}
                                </span>

                                <span v-else class="u-colored-warning status-text">
                                    {{ requestedProduct.Status }}
                                </span>
                            </div>

                            <div class="custom-table-th-mobile">

                            </div>

                            <div class="custom-table-th-mobile">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_REQUESTED'] }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_REJECTED'] }}
                            </div>

                            <div class="custom-table-td small">
                                {{ requestedProduct.Quantity }}
                            </div>

                            <div class="custom-table-td small">
                                {{ requestedProduct.QuantityRejected }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_APPROVED'] }}
                            </div>

                            <div class="custom-table-th-mobile">
                                {{ $store.state.translations.attributeLabels['RTN_PDT_QUANTITY_EXPECTED'] }}
                            </div>

                            <div class="custom-table-td small">
                                {{ requestedProduct.QuantityApproved }}
                            </div>

                            <div class="custom-table-td small">
                                {{ requestedProduct.QuantityExpected }}
                            </div>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    import {BCollapse, VBToggle} from 'bootstrap-vue-3';

    export default {
        components: {
            'b-collapse': BCollapse,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                orderId: this.$route.query.orderId,
                returnId: this.$route.query.returnId,
                isShowingDetails: false,
                requestedProducts: [],
                showModelNumber: null,
            }
        },
        created() {
            if (this.displayReturnDetails) {
                this.$store.dispatch('products/getAll', [this.returnId, this.orderId]).then((data) => {
                    this.requestedProducts = data;
                });

                this.$store.dispatch('mcpModule/getAll').then(() => {
                    this.showModelNumber = this.$store.state.mcpModule?.merchantSetups?.[this.$store.state.translations?.currentLanguage]?.[this.$route.query?.merchantSetupId]?.parameters?.showModelNumber;
                });
            }
        },
        methods: {
            showDetails() {
                this.isShowingDetails = !this.isShowingDetails;
            },
        },
        computed: {
            displayReturnDetails() {
                return this.$store.state?.portal?.parameters?.displayReturnDetails;
            }
        }
    }
</script>

<style>

</style>
