<template>
    <div class="page">
        <SspHeader activeMenuItem="create"/>

        <InstructionsSection contentBlockSection="CheckoutPage" progressBarCurrentStep="confirmation"></InstructionsSection>

        <form action="#">
            <ShowProductDetails class="pb-4"/>

            <div class="container sm">
                <div class="row" v-if="!initLoaded">
                    <div class="col-md-6 px-md-4">
                        <div class="form-group w-100">
                            {{ $store.state.translations.translations['Loading return details...'] }}
                        </div>
                    </div>
                </div>
                <div class="row" v-else-if="savingConfirmation">
                    <div class="col-md-6 px-md-4">
                        <div class="form-group w-100">
                            {{ $store.state.translations.translations['Reviewing and authorizing your return...'] }}
                        </div>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <InputFieldError attribute="attributes" :errors="errors"/>
                    </div>

                    <div class="col-12 col-sm-6 mb-4">
                        <label for="first-name">
                            {{ $store.state.translations.attributeLabels['RTN_LNA_ATTN_FIRST_NAME'] }} *
                        </label>

                        <input id="first-name"
                               type="text"
                               class="form-control"
                               ref="firstnameField"
                               v-model="user.firstname"
                        >

                        <InputFieldError attribute="RTN_RTN_ADDRESSEE_FIRST_NAME" :errors="errors"/>
                    </div>

                    <div class="col-12 col-sm-6 mb-4">
                        <label for="last-name">
                            {{ $store.state.translations.attributeLabels['RTN_LNA_ATTN_FAMILY_NAME'] }} *
                        </label>

                        <input id="last-name"
                               type="text"
                               class="form-control"
                               v-model="user.lastname"
                        >

                        <InputFieldError attribute="RTN_RTN_ADDRESSEE_LAST_NAME" :errors="errors"/>
                    </div>

                    <div class="col-12 col-sm-6 mb-4">
                        <label for="email">
                            {{ $store.state.translations.attributeLabels['RTN_RTN_EMAIL'] }} *
                        </label>

                        <input id="email"
                               type="email"
                               class="form-control"
                               v-model="user.email"
                               @change="validateField('RTN_RTN_EMAIL', user.email)"
                        >

                        <InputFieldError attribute="RTN_RTN_EMAIL" :errors="errors"/>
                    </div>

                    <div class="col-12 col-sm-6 mb-4" v-if="params.whoFillsInPhoneNumber === 'customer'">
                        <label for="phone">
                            {{ $store.state.translations.attributeLabels['RTN_LNA_PHONE'] }} *
                        </label>

                        <input id="phone"
                               type="text"
                               class="form-control"
                               v-model="user.phone"
                               @change="validateField('RTN_RTN_PHONE', user.phone)"
                        >

                        <InputFieldError attribute="RTN_RTN_PHONE" :errors="errors"/>
                    </div>

                    <div class="col-12 col-sm-6 mb-4" v-if="params && params.showTaxIdentificationNumberInput">
                        <label for="taxIdentificationNumber">
                            {{ $store.state.translations.attributeLabels['RTN_LNA_TAX_IDENTIFICATION_NUMBER'] }}
                        </label>

                        <input id="taxIdentificationNumber"
                               type="text"
                               class="form-control"
                               v-model="user.taxIdentificationNumber"
                        >

                        <InputFieldError attribute="RTN_TAX_IDENTIFICATION_NUMBER" :errors="errors"/>
                    </div>

                    <div class="col-12 col-sm-6 mb-4" v-if="params && params.showOrganizationNameInput">
                        <label for="organization">
                            {{ $store.state.translations.attributeLabels['RTN_LNA_ORGANIZATION'] }}
                        </label>

                        <input id="organization"
                               type="text"
                               class="form-control"
                               v-model="user.organizationname"
                               :disabled="!!user.organizationId && user.organizationId !== ''"
                        >

                        <InputFieldError attribute="RTN_RTN_ORGANIZATIONNAME" :errors="errors"/>
                    </div>
                </div>
            </div>

            <section v-if="getPortalContent('TermsOfUse') && !savingConfirmation">
                <div class="container sm mt-5">
                    <div id="accordion" class="accordion">
                        <div class="accordion-card">
                            <div class="accordion-card-header" v-if="getPortalContent('TermsOfUse').title">
                                <h5 class="mx-3 my-0">
                                    {{ getPortalContent('TermsOfUse').title }}
                                </h5>
                            </div>

                            <div class="collapse-body">
                                <div class="card-body">
                                    <div v-html="getPortalContent('TermsOfUse').content"
                                         v-if="getPortalContent('TermsOfUse')"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap justify-content-center">
                        <label class="checkbox-container mr-3">
                            <input type="checkbox" class="form-control" v-model="termsCheckbox">
                            {{ $store.state.translations.translations['I have read and agree with the terms.'] }}

                            <span class="checkmark"></span>
                        </label>
                    </div>

                    <p v-if="showTermsRequiredMessage" class="d-flex justify-content-center text-danger">
                        {{ $store.state.translations.translations['You can only continue if you agree with the terms.'] }}
                    </p>
                </div>
            </section>

            <div class="container sm" v-if="!savingConfirmation">
                <InputFieldError attribute="general" :errors="errors"/>

                <div class="col-12 d-flex flex-column flex-md-row align-items-center justify-content-center pt-3 pt-md-4 mb-5">
                    <button type="button" class="btn md btn-secondary m-3" @click="previous()">
                        {{ $store.state.translations.translations['Back'] }}
                    </button>

                    <button type="button" name="button" class="btn md btn-primary m-1 m-md-3" @click="save()">
                        {{ $store.state.translations.translations['Request return'] }}
                    </button>
                </div>
            </div>
        </form>

        <SspFooter></SspFooter>
    </div>
</template>


<script>
    import SspHeader from '@/components/_Header.vue';
    import SspFooter from '@/components/_Footer.vue';
    import InstructionsSection from '@/components/_InstructionsSection.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ShowProductDetails from '@/components/_ShowProductDetails.vue';

    export default {
        name: 'CheckoutPage',
        components: {
            'SspHeader': SspHeader,
            'SspFooter': SspFooter,
            'InstructionsSection': InstructionsSection,
            'InputFieldError': InputFieldError,
            'ShowProductDetails': ShowProductDetails,
        },
        data() {
            return {
                errors: {},
                //!!+  + is convert to int !! is convert to bool
                approvalEnabled: '0',
                logisticsEnabled: '0',
                savingConfirmation: false,
                returnReference: false,
                returnData: false,
                submitResult: false,
                merchantSetupId: this.$route.query.merchantSetupId,
                user: {firstname: '', lastname: '', email: '', organizationId: '', organizationname: '', phone: '', taxIdentificationNumber: ''},
                returnId: this.$route.query.returnId,
                orderId: this.$route.query.orderId,
                termsCheckbox: false,
                showTermsRequiredMessage: false,
                params: {},
                loaded: {
                    returnPolicies: false,
                    currentUser: false,
                    additionalInfoStep: false,
                    checkoutPrefill: false
                },
                firstFieldFocussed: false
            };
        },
        created() {
            this.$store.dispatch('mcpModule/getAll').then(() => {
                this.merchantSetupsLoaded = true;
                this.approvalEnabled = !!+this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].ApprovalEnabled;
                this.logisticsEnabled = !!+this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].DynamicRouting;
                this.params = this.$store.state.mcpModule.merchantSetups[this.$store.state.translations.currentLanguage][this.merchantSetupId].parameters;
                this.loaded.returnPolicies = true;
            });

            if (this.$store.state.isLoggedIn) {
                this.$store.dispatch('getCurrentUser').then((data) => {
                    this.user.firstname = data.FirstName;
                    this.user.lastname = data.LastName;
                    this.user.email = data.Email;
                    this.user.phone = data.Phone;
                    if (data.OrganizationId) {
                        this.user.organizationId = data.OrganizationId;
                        this.user.organizationname = data.organization.Name;
                    }
                    this.loaded.currentUser = true;
                });
            }

            //load additional info
            this.$store.dispatch('getHasAdditionalInfoStep', this.returnId).then(() => {
                this.loaded.additionalInfoStep = true;
            });

            //load prefill data (used for orderconnector)
            const url = new URL(this.$store.state.portalUrl + 'return/checkoutPrefill');
            url.searchParams.set('orderId', this.orderId);
            url.searchParams.set('returnId', this.returnId);

            this.$http.get(url.href).then((data) => {
                const prefill = data.data.data.prefill;

                if (prefill) {
                    this.user.firstname = prefill.FirstName;
                    this.user.lastname = prefill.LastName;
                    this.user.email = prefill.Email;
                    this.user.phone = prefill.Phone;
                    this.user.organizationname = prefill.Company;
                }
                this.loaded.checkoutPrefill = true;
            });
        },
        computed: {
            initLoaded() {
                let allLoaded = ((!this.$store.state.isLoggedIn || this.loaded.currentUser) &&
                    this.loaded.returnPolicies &&
                    this.loaded.additionalInfoStep &&
                    this.loaded.checkoutPrefill);

                if(allLoaded && !this.firstFieldFocussed) {
                    this.setFirstFocusField();
                }

                return allLoaded;
            }
        },
        methods: {
            setFirstFocusField() {
                this.$nextTick(() => {
                    this.$refs.firstnameField.focus();
                    this.firstFieldFocussed = true;
                });
            },
            getPortalContent(section) {
                if (this.$store.state.portalContent.content && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section] && this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_ACTIVE != 0) {
                    if (!this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_TITLE &&
                        !this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_CONTENT) {
                        return false;
                    }

                    return {
                        'title': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_TITLE,
                        'content': this.$store.state.portalContent.content[this.$store.state.translations.currentLanguage][section].RTN_PCT_CONTENT,
                    };
                }

                return false;
            },
            save() {
                if (this.getPortalContent('TermsOfUse') && !this.termsCheckbox) {
                    this.showTermsRequiredMessage = true;
                    return false;
                }

                this.savingConfirmation = true;

                const url = new URL(this.$store.state.portalUrl + 'return/checkout');
                url.searchParams.set('returnId', this.returnId);

                const params = new URLSearchParams();
                params.append('return[email]', this.user.email);
                params.append('return[firstname]', this.user.firstname);
                params.append('return[lastname]', this.user.lastname);
                if(this.params.whoFillsInPhoneNumber === 'customer')
                    params.append('return[phone]', this.user.phone);
                params.append('return[organizationname]', this.user.organizationname);
                params.append('return[taxIdentificationNumber]', this.user.taxIdentificationNumber);

                this.$http.post(url.href, params).then((data) => {
                    if (data.data.data.errors) {
                        this.savingConfirmation = false;
                        this.errors = data.data.data.errors;

                        if (this.errors && typeof this.errors !== 'object' && !Array.isArray(this.errors)) {
                            this.errors = {general: this.errors};
                        }
                    } else {
                        //is geen draft meer het is of authorization/requested of logistics/approved
                        this.$store.commit('draftReturns/delete', data.data.data.return.ReturnId);

                        var trackStatusses = [
                            'authorisation/requested',
                            'settlement/requested',
                            'completed',
                            'processing/requested',
                        ];

                        if (trackStatusses.indexOf(data.data.data.return.Status) != -1) {
                            this.$store.commit('trackReturns/add', data.data.data.return);
                        } else {
                            this.$store.commit('shipReturns/add', data.data.data.return);
                        }

                        if (data?.data?.data?.result)
                            this.submitResult = data.data.data.result;
                        if (data?.data?.data?.return.ReturnReference)
                            this.returnReference = data.data.data.return.ReturnReference;
                        if (data?.data?.data?.return)
                            this.returnData = data.data.data.return;

                        if (data?.data?.data?.products) {
                            this.$store.commit('products/set', {
                                orderId: this.orderId,
                                products: data.data.data.products
                            });
                        }

                        this.next();
                    }
                });
            },
            previous() {
                let prevPage = null;

                if (this.$store.state.hasAdditionalInfoStep?.[this.returnId] === true) {
                    prevPage = 'AdditionalInfo';
                } else {
                    const mcp = this.$store.state.mcpModule?.merchantSetups?.[this.$store.state.translations?.currentLanguage]?.[this.merchantSetupId];
                    prevPage = mcp.hasConnector ? 'OrderConnectorProductPage' : 'ProductPage';
                }

                this.$router.push({
                    name: prevPage,
                    query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
                });
            },
            next() {
                if (
                    (!this.approvalEnabled && !this.logisticsEnabled) ||
                    this.returnData.Status === 'settlement/requested' ||
                    this.returnData.Status === 'completed'
                ) {
                    if (this.params?.useSettlementProposals) {
                        this.$router.push({
                            name: 'SelectSettlementProposal',
                            query: {
                                returnId: this.returnId,
                                submitResult: 'submit-non-returnable',
                                returnReference: this.returnReference
                            }
                        });
                    } else {
                        this.$router.push({
                            name: 'SubmitForApproval',
                            query: {submitResult: 'submit-non-returnable', returnReference: this.returnReference}
                        });
                    }
                }
                //end step / submit
                else if (this.submitResult !== 'submit-logistics-approved' && this.approvalEnabled) {
                    this.$router.push({
                        name: 'SubmitForApproval',
                        query: {submitResult: this.submitResult, returnReference: this.returnReference}
                    });
                }
                //address
                else {
                    this.$router.push({
                        path: 'AddAddress',
                        query: {returnId: this.returnId, orderId: this.orderId, merchantSetupId: this.merchantSetupId}
                    });
                }
            },
            validateField(field, value) {
                delete this.errors[field];

                const url = new URL(this.$store.state.portalUrl + 'return/validateField');
                const params = new URLSearchParams();
                params.append('return[id]', this.returnId);
                params.append('return[field]', field);
                params.append('return[' + field + ']', value);

                this.$http.post(url.href, params).then((data) => {
                    if (data.data.data.error) {
                        this.errors[field] = data.data.data.error;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
